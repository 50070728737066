import React from 'react';
import { useRecoilValue } from 'recoil';
import { IntlProvider } from 'react-intl';
import { localeStateSelector } from '@recoil/locale';
import messages from '@i18n/messages';

function ScmIntlProvider({ children }) {
  const locale = useRecoilValue(localeStateSelector);
  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en"
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
}

export default ScmIntlProvider;
