import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { FetchProvider } from '@bjornagh/use-fetch';
import { Worker } from '@react-pdf-viewer/core';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import initializeState from '@recoil/initialize-state';
import theme from '@styles/lavieclaire';
import Loader from '@components/Loader/Loader';
import IntlProvider from '@components/IntlProvider/IntlProvider';
import LocalizationProvider from '@components/Localization/LocalizationProvider';
import TokenCapture from '@components/Token/TokenCapture';

import LvcRoutes from './lavieclaire-routes';
import CurrentCampaignFetcher from './CurrentCampaignFetcher';

const cache = new Map();
function chooseTokenNavigation() {
  return {
    pathname: '/',
  };
}

function LvcApp() {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
      <SnackbarProvider maxSnack={10}>
        <RecoilRoot initializeState={initializeState}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<Loader sx={{ height: '100vh' }} />}>
              <Router>
                <TokenCapture chooseNavigation={chooseTokenNavigation}>
                  <FetchProvider cache={cache}>
                    <CurrentCampaignFetcher>
                      <IntlProvider>
                        <LocalizationProvider>
                          <LvcRoutes />
                        </LocalizationProvider>
                      </IntlProvider>
                    </CurrentCampaignFetcher>
                  </FetchProvider>
                </TokenCapture>
              </Router>
            </Suspense>
          </ThemeProvider>
        </RecoilRoot>
      </SnackbarProvider>
    </Worker>
  );
}

export default LvcApp;
