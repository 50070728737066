import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';

function ErrorMessage({
  error,
  mapper = x => x,
  sx = null,
  severity = 'error',
}) {
  if (typeof error === 'string') {
    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessage id={`Errors.${error}`} />
      </Alert>
    );
  }

  if (error instanceof Error) {
    const id =
      error.message === 'Failed to fetch'
        ? `Errors.FailedToFetch`
        : `Errors.${error.message}`;
    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessage id={id} />
      </Alert>
    );
  }

  if (error?.data?.errors) {
    return (
      <Alert severity={severity} sx={sx}>
        {Object.values(error?.data?.errors ?? {})
          .flat()
          .map(mapper)
          .map(key => (
            <FormattedMessage tagName="div" key={key} id={`Errors.${key}`} />
          ))}
      </Alert>
    );
  }

  if (error?.data?.message) {
    return (
      <Alert severity={severity} sx={sx}>
        {error.data.message}
      </Alert>
    );
  }

  if (error?.data?.title) {
    return (
      <Alert severity="error" sx={sx}>
        {error.data.title}
      </Alert>
    );
  }

  return null;
}

export default ErrorMessage;
