import React from 'react';
import { Formik, Form } from 'formik';

function FormikProvider({
  children,
  schema,
  initialValues = {},
  onSubmit = () => { },
  FormProps = {},
  ...props
}) {
  const onSubmitCallback = React.useCallback(
    (values, context) => {
      onSubmit(schema ? schema.cast(values) : values, context);
    },
    [schema, onSubmit]
  );
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmitCallback}
      {...props}
    >
      <Form {...FormProps} noValidate>
        {children}
      </Form>
    </Formik>
  );
}

export default FormikProvider;
