import React from 'react';
import Box from '@mui/material/Box';

function Spinner() {
  return (
    <Box
      component="span"
      sx={{
        width: 48,
        height: 48,
        margin: 1,
        display: 'inline-block',
        position: 'relative',
        '&::before, &::after': {
          content: '""',
          width: 48,
          height: 48,
          border: '2px solid',
          borderColor: theme => theme.palette.darkPaper,
          position: 'absolute',
          top: 0,
          left: 0,
          animation: 'spinner-rotation 2.5s ease-in-out infinite',
        },
        '&::after': {
          borderColor: 'primary.main',
          animationDelay: '1.25s',
        },
      }}
    />
  );
}

export default Spinner;
