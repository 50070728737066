export const tenantRoleNames = {
  administrators: 'Administrators',
  external: 'External',
  offerLeader: 'Offer Leader',
  marketLeader: 'Market Leader',
  expert: 'Expert',
  supplier: 'Supplier',
  productManager: 'Product Manager',
  assistantProductManager: 'Assistant Product Manager',
  productLeader: 'Product Leader',
};

export const rolesAllowedToViewAdminPanel = [tenantRoleNames.administrators];

export const rolesAllowedToViewExperts = [
  tenantRoleNames.offerLeader,
  tenantRoleNames.productManager,
  tenantRoleNames.assistantProductManager,
  tenantRoleNames.administrators,
];

export const rolesAllowedToAddDocuments = [
  tenantRoleNames.offerLeader,
  tenantRoleNames.productManager,
  tenantRoleNames.assistantProductManager,
  tenantRoleNames.administrators,
];

export const rolesAllowedToSignDocuments = [
  tenantRoleNames.offerLeader,
  tenantRoleNames.productManager,
  tenantRoleNames.assistantProductManager,
  tenantRoleNames.administrators,
];
