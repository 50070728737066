import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, getIn } from 'formik';

import ContributorPicker from '@components/Pickers/ContributorPicker';

function MuiContributorPicker({
  usersOnly,
  multiple,
  customLabel,
  field,
  form: { touched, errors, setFieldValue },
  url = '/identity/roles-and-users',
  label = <FormattedMessage id="Workflow.ContributorPicker.Label" />,
  ...props
}) {
  const { name } = field;
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const hasError = Boolean(touch) && Boolean(error);

  return (
    <ContributorPicker
      usersOnly={usersOnly}
      multiple={multiple}
      margin="normal"
      url={url}
      error={hasError}
      helperText={hasError && <FormattedMessage id={error} />}
      label={customLabel || label}
      className="Picker-root"
      {...props}
      {...field}
      onChange={(_, contributor) => setFieldValue(name, contributor)}
    />
  );
}

function FormikContributorPicker(props) {
  return <Field component={MuiContributorPicker} {...props} />;
}

export default FormikContributorPicker;
