import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useTenantRoles from '@hooks/useTenantRoles';

function TenantRoleRoute({ roles = [], mode = 'at-least' }) {
  const hasPermission = useTenantRoles({ roles, mode });
  if (!hasPermission) {
    return <Navigate to="/unauthorized" replace />;
  }
  return <Outlet />;
}

export default TenantRoleRoute;
