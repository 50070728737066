import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

function RoleChip({ name, ...rest }) {
  return <Chip icon={<PeopleAltIcon />} label={name} {...rest} />;
}

RoleChip.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default RoleChip;
