import React from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export function CustomConfirmDialog({
  openState,
  onClose,
  onSubmit,
  title,
  cancelText,
  confirmText,
  content,
  fullScreen,
}) {
  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
    openState.update(false);
  };

  const handleSubmit = event => {
    onSubmit(event);
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen ?? false}
      maxWidth="md"
      open={openState.value}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {cancelText ?? 'Cancel'}
        </Button>
        <Button variant="contained" onClick={handleSubmit} autoFocus>
          {confirmText ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
