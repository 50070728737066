import React from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function UnauthorizedView() {
  return (
    <Stack sx={{ pt: 4 }}>
      <Alert severity="error">
        <AlertTitle>
          <FormattedMessage id="Unauthorized.Title" />
        </AlertTitle>
        <FormattedMessage id="Unauthorized.Message" />
      </Alert>
    </Stack>
  );
}

export default UnauthorizedView;
