import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FastField, Field } from 'formik';
import TextField from '@mui/material/TextField';

import { valueFromPath } from '@utils/json-path';

function MuiTextField({
  field,
  form: { touched, errors },
  readOnly,
  ...props
}) {
  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);
  return (
    <TextField
      fullWidth
      margin="normal"
      autoComplete="off"
      {...props}
      {...field}
      InputProps={{
        readOnly,
        ...props?.InputProps,
      }}
      onChange={props?.onChange ?? field?.onChange}
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessage
            id={error}
            values={{ label: props?.label ?? '', value: field?.value ?? '' }}
          />
        )
      }
    />
  );
}

function FormikTextField({ as = 'fastfield', ...props }) {
  if (as === 'fastfield') {
    return <FastField component={MuiTextField} {...props} />;
  }
  return <Field component={MuiTextField} {...props} />;
}

export default FormikTextField;
