import React, { useEffect, useMemo, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import useFetch from '@hooks/useFetch';
import useDebounced from '@hooks/useDebounced';

import messages from '@i18n/keys';
import { label } from '@utils/contributor-helpers';

function ContributorPicker({
  url: urlProp = '/identity/roles-and-users',
  params: paramsProp,
  value: valueProp,
  onChange: onChangeProp,
  PickerProps = {},
  disabled = false,
  usersOnly,
  multiple,
  urlBody,
  emptyUser,
  ...props
}) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounced(inputValue, 300);

  const urlParams = useMemo(
    () =>
      createSearchParams({
        ...paramsProp,
        ...(debouncedInputValue && debouncedInputValue !== 'undefined'
          ? {
              query: debouncedInputValue,
              ...(urlBody?.contributor
                ? { filterRoleIds: urlBody?.contributor?.entity?.id || '' }
                : {}),
            }
          : {}),
        limit: 5,
      }),
    [paramsProp, debouncedInputValue, urlBody?.contributor?.entity?.id]
  );

  const url = `${urlProp}?${urlParams}`;

  const { fetching, data, doFetch } = useFetch({
    url,
    lazy: true,
    cachePolicy: 'network-only',
  });

  const usersOptions = React.useMemo(() => {
    if (!data) return [];
    const type = 1;
    const typeLabel = formatMessage({
      id: messages.contributor.pickers.type[type].label,
    });
    const result = (data.users ?? []).map(u => ({
      type,
      typeLabel,
      entity: u,
    }));
    if (emptyUser) {
      result.push({
        type,
        typeLabel,
        entity: {
          id: '00000000-0000-0000-0000-000000000000',
        },
      });
    }
    return result;
  }, [data, formatMessage, emptyUser]);

  const allOptions = React.useMemo(() => {
    if (!data) return [];
    return [
      ...usersOptions,
      ...(data.roles ?? []).map(r => ({
        type: 2,
        typeLabel: formatMessage({
          id: messages.contributor.pickers.type[2].label,
        }),
        entity: r,
      })),
    ];
  }, [data, usersOptions, formatMessage]);

  const isOpen =
    open &&
    debouncedInputValue === inputValue &&
    Boolean(debouncedInputValue) &&
    !fetching;

  useEffect(() => {
    if (open && url && debouncedInputValue) {
      doFetch();
    }
  }, [open, url, debouncedInputValue, doFetch]);

  const options = useMemo(
    () =>
      usersOnly
        ? usersOptions.sort((a, b) => -b.typeLabel.localeCompare(a.typeLabel))
        : allOptions.sort((a, b) => -b.typeLabel.localeCompare(a.typeLabel)),
    [allOptions, usersOnly, usersOptions]
  );

  return (
    <Autocomplete
      fullWidth
      {...PickerProps}
      open={isOpen}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={valueProp}
      multiple={multiple}
      onChange={onChangeProp}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      isOptionEqualToValue={(option, value) =>
        option.entity.id === value.entity.id
      }
      getOptionLabel={params => {
        const res = label(params, formatMessage);
        return res || '';
      }}
      groupBy={option => option.typeLabel}
      options={options}
      loading={fetching}
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default ContributorPicker;
