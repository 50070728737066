/* generated */
/* eslint-disable */

export default {
  "add": {
    "document": {
      "modal": {
        "fields": {
          "template": {
            "name": {
              "title": "Add.Document.Modal.Fields.Template.Name.Title"
            },
            "select": {
              "title": "Add.Document.Modal.Fields.Template.Select.Title"
            }
          }
        },
        "title": "Add.Document.Modal.Title"
      }
    }
  },
  "admin": {
    "administration": "Admin.Administration",
    "card": {
      "button": {
        "label": "Admin.Card.Button.Label"
      }
    },
    "contracttypesview": {
      "buttons": {
        "createbusinesscooperation": {
          "label": "Admin.ContractTypesView.Buttons.CreateBusinessCooperation.Label"
        },
        "createpack": {
          "alert": "Admin.ContractTypesView.Buttons.CreatePack.Alert",
          "label": "Admin.ContractTypesView.Buttons.CreatePack.Label"
        }
      },
      "create": {
        "new": {
          "business": {
            "cooperation": {
              "type": "Admin.ContractTypesView.Create.New.Business.Cooperation.Type"
            }
          },
          "contract": {
            "type": "Admin.ContractTypesView.Create.New.Contract.Type"
          }
        }
      },
      "dropdowns": {
        "placeholder": "Admin.ContractTypesView.Dropdowns.Placeholder"
      },
      "sections": {
        "contract": {
          "packages": "Admin.ContractTypesView.Sections.Contract.Packages"
        },
        "type": {
          "of": {
            "business": {
              "cooperations": "Admin.ContractTypesView.Sections.Type.Of.Business.Cooperations"
            }
          }
        }
      },
      "type": {
        "of": {
          "business": {
            "cooperations": {
              "alert": "Admin.ContractTypesView.Type.Of.Business.Cooperations.Alert"
            }
          }
        }
      }
    },
    "controlpanel": "Admin.ControlPanel",
    "help": {
      "information": {
        "management": "Admin.Help.Information.Management"
      }
    },
    "manage": {
      "templates": "Admin.Manage.Templates"
    },
    "managereferences": "Admin.ManageReferences",
    "negotiation": {
      "campaign": {
        "management": "Admin.Negotiation.Campaign.Management"
      }
    },
    "template": {
      "management": "Admin.Template.Management"
    }
  },
  "admincontracttypesview": {
    "editcard": {
      "delete": {
        "description": "AdminContractTypesView.EditCard.Delete.Description",
        "title": "AdminContractTypesView.EditCard.Delete.Title"
      },
      "edit": {
        "name": "AdminContractTypesView.EditCard.Edit.Name"
      },
      "optional": {
        "name": "AdminContractTypesView.EditCard.Optional.Name"
      }
    }
  },
  "admintable": {
    "buttons": {
      "save": {
        "label": "AdminTable.Buttons.Save.Label"
      }
    }
  },
  "administration": {
    "duplicatetemplatemodal": {
      "buttons": {
        "create": {
          "label": "Administration.DuplicateTemplateModal.Buttons.Create.Label"
        }
      },
      "label": "Administration.DuplicateTemplateModal.Label"
    }
  },
  "assistant": {
    "supplierdetails": {
      "cooperationfollowup": {
        "accordion": {
          "label": "Assistant.SupplierDetails.CooperationFollowUp.Accordion.Label"
        },
        "buttons": {
          "save": {
            "label": "Assistant.SupplierDetails.CooperationFollowUp.Buttons.Save.Label"
          }
        },
        "commercial": {
          "cooperation": {
            "budget": "Assistant.SupplierDetails.CooperationFollowUp.Commercial.Cooperation.Budget"
          }
        }
      },
      "genrictable": {
        "error": {
          "snackbar": "Assistant.SupplierDetails.GenricTable.Error.Snackbar"
        },
        "header": {
          "cooperationfollowup": {
            "commercial": {
              "cooperation": {
                "with": {
                  "endorsement": "Assistant.SupplierDetails.GenricTable.Header.CooperationFollowUp.Commercial.Cooperation.With.Endorsement"
                },
                "without": {
                  "endorsement": "Assistant.SupplierDetails.GenricTable.Header.CooperationFollowUp.Commercial.Cooperation.Without.Endorsement"
                }
              }
            }
          }
        },
        "rows": {
          "leaflet": "Assistant.SupplierDetails.GenricTable.Rows.Leaflet",
          "national": {
            "brand": {
              "animation": "Assistant.SupplierDetails.GenricTable.Rows.National.Brand.Animation"
            }
          },
          "novelty": "Assistant.SupplierDetails.GenricTable.Rows.Novelty",
          "product": {
            "book": "Assistant.SupplierDetails.GenricTable.Rows.Product.Book"
          },
          "promo": {
            "section": {
              "thematic": {
                "folder": "Assistant.SupplierDetails.GenricTable.Rows.Promo.Section.Thematic.Folder"
              }
            }
          },
          "publication": {
            "national": {
              "brand": {
                "catalog": "Assistant.SupplierDetails.GenricTable.Rows.Publication.National.Brand.Catalog"
              }
            }
          },
          "training": "Assistant.SupplierDetails.GenricTable.Rows.Training"
        },
        "success": {
          "snackbar": "Assistant.SupplierDetails.GenricTable.Success.Snackbar"
        }
      },
      "globalagreement": {
        "accordion": {
          "label": "Assistant.SupplierDetails.GlobalAgreement.Accordion.Label"
        },
        "discount": {
          "invoice": "Assistant.SupplierDetails.GlobalAgreement.Discount.Invoice"
        },
        "end": {
          "of": {
            "year": {
              "discount": "Assistant.SupplierDetails.GlobalAgreement.End.Of.Year.Discount"
            }
          }
        },
        "other": {
          "obligations": "Assistant.SupplierDetails.GlobalAgreement.Other.Obligations"
        }
      },
      "supplierinformation": {
        "accordion": {
          "label": "Assistant.SupplierDetails.SupplierInformation.Accordion.Label"
        },
        "breakdown": {
          "turnover": {
            "by": {
              "supplier": {
                "account": "Assistant.SupplierDetails.SupplierInformation.Breakdown.Turnover.By.Supplier.Account"
              }
            }
          }
        },
        "contact": {
          "commercial": "Assistant.SupplierDetails.SupplierInformation.Contact.Commercial",
          "signatory": "Assistant.SupplierDetails.SupplierInformation.Contact.Signatory"
        },
        "contract": {
          "languages": "Assistant.SupplierDetails.SupplierInformation.Contract.Languages",
          "referent": "Assistant.SupplierDetails.SupplierInformation.Contract.Referent"
        },
        "declarative": {
          "supplier": {
            "data": "Assistant.SupplierDetails.SupplierInformation.Declarative.Supplier.Data"
          },
          "turnover": {
            "history": "Assistant.SupplierDetails.SupplierInformation.Declarative.Turnover.History"
          }
        },
        "evolution": {
          "turnover": {
            "achieved": {
              "by": {
                "lavieclaire": "Assistant.SupplierDetails.SupplierInformation.Evolution.Turnover.Achieved.By.LaVieClaire"
              }
            }
          }
        },
        "financial": {
          "dependency": "Assistant.SupplierDetails.SupplierInformation.Financial.Dependency"
        },
        "name": "Assistant.SupplierDetails.SupplierInformation.Name",
        "not": {
          "filled": {
            "in": {
              "information": "Assistant.SupplierDetails.SupplierInformation.Not.Filled.In.Information"
            }
          }
        },
        "number": {
          "of": {
            "employees": "Assistant.SupplierDetails.SupplierInformation.Number.Of.Employees"
          }
        },
        "produced": {
          "with": {
            "lavieclaire": "Assistant.SupplierDetails.SupplierInformation.Produced.With.LaVieClaire"
          }
        },
        "start": {
          "date": {
            "of": {
              "collaboration": "Assistant.SupplierDetails.SupplierInformation.Start.Date.Of.Collaboration"
            }
          }
        },
        "supplier": {
          "global": {
            "turnover": "Assistant.SupplierDetails.SupplierInformation.Supplier.Global.Turnover"
          }
        },
        "to": {
          "be": {
            "completed": {
              "by": {
                "the": {
                  "supplier": "Assistant.SupplierDetails.SupplierInformation.To.Be.Completed.By.The.Supplier"
                }
              }
            }
          }
        }
      }
    }
  },
  "authorize": {
    "button": "Authorize.Button",
    "fields": {
      "tenant": {
        "label": "Authorize.Fields.Tenant.Label",
        "required": "Authorize.Fields.Tenant.Required"
      }
    }
  },
  "buttons": {
    "administrator": {
      "add": "Buttons.Administrator.Add"
    },
    "projectmanager": {
      "add": "Buttons.ProjectManager.Add"
    },
    "teams": {
      "add": "Buttons.Teams.Add"
    },
    "tenants": {
      "add": "Buttons.Tenants.Add"
    }
  },
  "campagindocuments": {
    "campagindocumentsview": {
      "buttons": {
        "launchcampaign": {
          "label": "CampaginDocuments.CampaginDocumentsView.Buttons.LaunchCampaign.Label"
        },
        "updateassignments": {
          "label": "CampaginDocuments.CampaginDocumentsView.Buttons.UpdateAssignments.Label"
        },
        "updatecampaign": {
          "label": "CampaginDocuments.CampaginDocumentsView.Buttons.UpdateCampaign.Label"
        },
        "updatecampaigns": {
          "label": "CampaginDocuments.CampaginDocumentsView.Buttons.UpdateCampaigns.Label"
        }
      },
      "delete": {
        "modal": {
          "description": "CampaginDocuments.CampaginDocumentsView.Delete.Modal.Description",
          "title": "CampaginDocuments.CampaginDocumentsView.Delete.Modal.Title"
        }
      }
    }
  },
  "campaginmanagement": {
    "campaginmanagementview": {
      "buttons": {
        "nextpage": {
          "label": "CampaginManagement.CampaginManagementView.Buttons.NextPage.Label"
        },
        "previouspage": {
          "label": "CampaginManagement.CampaginManagementView.Buttons.PreviousPage.Label"
        }
      }
    }
  },
  "campaigndocumentsview": {
    "delete": {
      "modal": {
        "attention": "CampaignDocumentsView.Delete.Modal.Attention"
      }
    }
  },
  "campaignmanagement": {
    "button": {
      "adddocument": {
        "label": "CampaignManagement.Button.AddDocument.Label"
      }
    },
    "campaignmanagementtable": {
      "supplierconfiguration": {
        "confirm": {
          "content": "CampaignManagement.CampaignManagementTable.SupplierConfiguration.Confirm.Content",
          "title": "CampaignManagement.CampaignManagementTable.SupplierConfiguration.Confirm.Title"
        }
      }
    },
    "document": {
      "url": {
        "documents": {
          "info": "CampaignManagement.Document.Url.Documents.Info"
        },
        "notebook": "CampaignManagement.Document.Url.NoteBook",
        "supplier": {
          "info": "CampaignManagement.Document.Url.Supplier.Info"
        },
        "vendor": "CampaignManagement.Document.Url.Vendor"
      }
    },
    "sections": {
      "documentstoattachbysupplier": {
        "title": "CampaignManagement.Sections.DocumentsToAttachBySupplier.Title"
      },
      "documentstosendtosupplier": {
        "alert": "CampaignManagement.Sections.DocumentsToSendToSupplier.Alert",
        "title": "CampaignManagement.Sections.DocumentsToSendToSupplier.Title"
      }
    }
  },
  "campaignmanagementview": {
    "buttons": {
      "export": {
        "label": "CampaignManagementView.Buttons.Export.Label"
      },
      "loading": {
        "label": "CampaignManagementView.Buttons.Loading.Label"
      },
      "sort": {
        "label": "CampaignManagementView.Buttons.Sort.Label"
      }
    },
    "filters": {
      "all": "CampaignManagementView.Filters.All",
      "deleteall": "CampaignManagementView.Filters.DeleteAll",
      "dropdowns": {
        "assistant": "CampaignManagementView.Filters.DropDowns.Assistant",
        "buyer": "CampaignManagementView.Filters.DropDowns.Buyer",
        "purchasing": {
          "manger": "CampaignManagementView.Filters.DropDowns.Purchasing.Manger"
        },
        "type": {
          "of": {
            "contract": "CampaignManagementView.Filters.DropDowns.Type.Of.Contract"
          }
        }
      },
      "title": "CampaignManagementView.Filters.Title"
    },
    "header": {
      "supplierrecord": "CampaignManagementView.Header.SupplierRecord"
    },
    "supplier": {
      "of": {
        "each": {
          "buyer": "CampaignManagementView.Supplier.Of.Each.Buyer"
        }
      }
    },
    "urls": {
      "campaign": {
        "setting": "CampaignManagementView.Urls.Campaign.Setting"
      },
      "role": {
        "management": "CampaignManagementView.Urls.Role.Management"
      }
    }
  },
  "campaigns": {
    "dialogs": {
      "create": {
        "title": "Campaigns.Dialogs.Create.Title"
      }
    },
    "fields": {
      "name": {
        "label": "Campaigns.Fields.Name.Label",
        "required": "Campaigns.Fields.Name.Required"
      },
      "year": {
        "invalid": "Campaigns.Fields.Year.Invalid",
        "label": "Campaigns.Fields.Year.Label",
        "required": "Campaigns.Fields.Year.Required"
      }
    },
    "item": {
      "buttons": {
        "launch": "Campaigns.Item.Buttons.Launch"
      },
      "information": "Campaigns.Item.Information",
      "negociated": {
        "label": "Campaigns.Item.Negociated.Label",
        "type": {
          "amendment": "Campaigns.Item.Negociated.Type.Amendment",
          "label": "Campaigns.Item.Negociated.Type.Label",
          "renegociation": "Campaigns.Item.Negociated.Type.Renegociation"
        }
      },
      "signed": {
        "label": "Campaigns.Item.Signed.Label"
      },
      "supporting": {
        "label": "Campaigns.Item.Supporting.Label"
      },
      "title": "Campaigns.Item.Title"
    },
    "list": {
      "buttons": {
        "add": "Campaigns.List.Buttons.Add"
      },
      "information": "Campaigns.List.Information",
      "label": "Campaigns.List.Label"
    }
  },
  "campaingmanagement": {
    "campaign": {
      "snackbar": {
        "error": "CampaingManagement.Campaign.Snackbar.Error",
        "update": {
          "success": "CampaingManagement.Campaign.Snackbar.Update.Success"
        }
      }
    }
  },
  "cards": {
    "document": {
      "documentprogresscard": {
        "createdon": {
          "label": "Cards.Document.DocumentProgressCard.CreatedOn.Label"
        },
        "notapplicable": "Cards.Document.DocumentProgressCard.NotApplicable",
        "signedon": {
          "label": "Cards.Document.DocumentProgressCard.SignedOn.Label"
        },
        "status": {
          "label": "Cards.Document.DocumentProgressCard.Status.Label"
        }
      }
    }
  },
  "chip": {
    "completion": "Chip.Completion",
    "draft": "Chip.Draft",
    "generate": "Chip.Generate",
    "lvcfilling": "Chip.LVCFilling",
    "missing": "Chip.Missing",
    "negotiation": "Chip.Negotiation",
    "notconcerned": "Chip.NotConcerned",
    "optional": "Chip.Optional",
    "pending": "Chip.Pending",
    "published": "Chip.Published",
    "received": "Chip.Received",
    "signed": "Chip.Signed",
    "supplier": "Chip.Supplier",
    "validated": "Chip.Validated",
    "verify": "Chip.Verify",
    "waiting": "Chip.Waiting"
  },
  "completion": {
    "clausevariant": {
      "label": "Completion.ClauseVariant.Label"
    },
    "conditionoperator": {
      "label": "Completion.ConditionOperator.Label",
      "values": {
        "equal": "Completion.ConditionOperator.Values.Equal",
        "notequal": "Completion.ConditionOperator.Values.NotEqual"
      }
    },
    "conditionvalue": {
      "label": "Completion.ConditionValue.Label"
    },
    "data": {
      "label": "Completion.Data.Label"
    },
    "field": {
      "label": "Completion.Field.Label"
    },
    "item": {
      "type": {
        "label": "Completion.Item.Type.Label",
        "values": {
          "clausevariantchoice": "Completion.Item.Type.Values.ClauseVariantChoice",
          "variablevalue": "Completion.Item.Type.Values.VariableValue"
        }
      }
    },
    "items": {
      "buttons": {
        "add": {
          "label": "Completion.Items.Buttons.Add.Label"
        }
      },
      "label": "Completion.Items.Label"
    },
    "novariables": "Completion.NoVariables",
    "referential": {
      "label": "Completion.Referential.Label"
    },
    "settings": {
      "save": {
        "missinginfo": {
          "error": "Completion.Settings.Save.MissingInfo.Error"
        },
        "success": "Completion.Settings.Save.Success"
      },
      "title": "Completion.Settings.Title"
    },
    "unknownfield": {
      "label": "Completion.UnknownField.Label"
    }
  },
  "components": {
    "viewer": {
      "error": {
        "defaultmessage": "Components.Viewer.Error.DefaultMessage"
      }
    }
  },
  "contract": {
    "content": {
      "callexpert": {
        "error": "Contract.Content.CallExpert.Error",
        "success": "Contract.Content.CallExpert.Success"
      },
      "save": {
        "success": "Contract.Content.Save.Success"
      }
    },
    "dialogs": {
      "delete": {
        "message": "Contract.Dialogs.Delete.Message",
        "title": "Contract.Dialogs.Delete.Title"
      },
      "signature": {
        "cancel": {
          "paragraph": "Contract.Dialogs.Signature.Cancel.Paragraph",
          "title": "Contract.Dialogs.Signature.Cancel.Title"
        },
        "send": {
          "paragraph": "Contract.Dialogs.Signature.Send.Paragraph",
          "title": "Contract.Dialogs.Signature.Send.Title"
        }
      },
      "update": {
        "message": "Contract.Dialogs.Update.Message",
        "title": "Contract.Dialogs.Update.Title"
      }
    },
    "signature": {
      "cancel": {
        "button": "Contract.Signature.Cancel.Button",
        "success": "Contract.Signature.Cancel.Success"
      },
      "send": {
        "button": "Contract.Signature.Send.Button",
        "success": "Contract.Signature.Send.Success"
      }
    },
    "workflow": {
      "buttons": {
        "complete": "Contract.Workflow.Buttons.Complete"
      },
      "complete": {
        "success": "Contract.Workflow.Complete.Success"
      },
      "dialog": {
        "complete": {
          "explanation": "Contract.Workflow.Dialog.Complete.Explanation",
          "paragraph": "Contract.Workflow.Dialog.Complete.Paragraph",
          "title": "Contract.Workflow.Dialog.Complete.Title"
        },
        "invalidate": {
          "paragraph": "Contract.Workflow.Dialog.Invalidate.Paragraph",
          "title": "Contract.Workflow.Dialog.Invalidate.Title"
        },
        "validate": {
          "explanation": "Contract.Workflow.Dialog.Validate.Explanation",
          "paragraph": "Contract.Workflow.Dialog.Validate.Paragraph",
          "title": "Contract.Workflow.Dialog.Validate.Title"
        }
      },
      "invalidate": {
        "success": "Contract.Workflow.Invalidate.Success"
      },
      "validate": {
        "success": "Contract.Workflow.Validate.Success"
      }
    }
  },
  "contributor": {
    "pickers": {
      "type": {
        "0": {
          "label": "Contributor.Pickers.Type.0.Label"
        },
        "1": {
          "label": "Contributor.Pickers.Type.1.Label"
        },
        "2": {
          "label": "Contributor.Pickers.Type.2.Label"
        }
      }
    }
  },
  "createtemplate": {
    "modal": {
      "button": {
        "create": "CreateTemplate.Modal.Button.Create"
      },
      "title": "CreateTemplate.Modal.Title"
    }
  },
  "createtemplatefolder": {
    "modal": {
      "button": {
        "create": "CreateTemplateFolder.Modal.Button.Create"
      },
      "title": "CreateTemplateFolder.Modal.Title"
    }
  },
  "dashboard": {
    "button": {
      "new": {
        "label": "Dashboard.Button.New.Label"
      }
    },
    "campaigns": {
      "folders": {
        "label": "Dashboard.Campaigns.Folders.Label"
      },
      "noresult": "Dashboard.Campaigns.NoResult"
    },
    "dashboardview": {
      "suppliers": {
        "files": "Dashboard.DashboardView.Suppliers.Files"
      }
    },
    "folder": {
      "new": {
        "label": "Dashboard.Folder.New.Label"
      },
      "rename": {
        "label": "Dashboard.Folder.Rename.Label"
      }
    },
    "leader": {
      "member": {
        "filescount": "Dashboard.Leader.Member.FilesCount"
      }
    },
    "new": {
      "template": {
        "label": "Dashboard.New.Template.Label"
      }
    },
    "recycle": {
      "bin": {
        "label": "Dashboard.Recycle.Bin.Label"
      }
    },
    "supplier": {
      "experts": {
        "save": {
          "success": "Dashboard.Supplier.Experts.Save.Success"
        }
      }
    },
    "suppliers": {
      "experts": {
        "selectexpertview": {
          "alerts": {
            "information": {
              "description": "Dashboard.Suppliers.Experts.SelectExpertView.Alerts.Information.Description",
              "title": "Dashboard.Suppliers.Experts.SelectExpertView.Alerts.Information.Title"
            }
          },
          "expert": {
            "label": "Dashboard.Suppliers.Experts.SelectExpertView.Expert.Label"
          },
          "header": {
            "affecteddepartment": "Dashboard.Suppliers.Experts.SelectExpertView.Header.AffectedDepartment",
            "idgha": "Dashboard.Suppliers.Experts.SelectExpertView.Header.IdGHA",
            "productmanager": "Dashboard.Suppliers.Experts.SelectExpertView.Header.ProductManager"
          },
          "noavailableexperts": "Dashboard.Suppliers.Experts.SelectExpertView.NoAvailableExperts",
          "nobody": "Dashboard.Suppliers.Experts.SelectExpertView.Nobody",
          "title": "Dashboard.Suppliers.Experts.SelectExpertView.Title"
        }
      },
      "negociated": {
        "buttons": {
          "add": {
            "label": "Dashboard.Suppliers.Negociated.Buttons.Add.Label"
          }
        },
        "dialog": {
          "renegociate": {
            "paragraph": "Dashboard.Suppliers.Negociated.Dialog.Renegociate.Paragraph",
            "title": "Dashboard.Suppliers.Negociated.Dialog.Renegociate.Title"
          }
        },
        "dialogs": {
          "create": {
            "title": "Dashboard.Suppliers.Negociated.Dialogs.Create.Title"
          },
          "delete": {
            "message": "Dashboard.Suppliers.Negociated.Dialogs.Delete.Message",
            "title": "Dashboard.Suppliers.Negociated.Dialogs.Delete.Title"
          },
          "update": {
            "title": "Dashboard.Suppliers.Negociated.Dialogs.Update.Title"
          }
        },
        "fields": {
          "name": {
            "label": "Dashboard.Suppliers.Negociated.Fields.Name.Label"
          },
          "template": {
            "label": "Dashboard.Suppliers.Negociated.Fields.Template.Label"
          }
        },
        "label": "Dashboard.Suppliers.Negociated.Label",
        "renegociationtype": {
          "amendment": {
            "label": "Dashboard.Suppliers.Negociated.RenegociationType.Amendment.Label"
          },
          "label": "Dashboard.Suppliers.Negociated.RenegociationType.Label",
          "template": {
            "label": "Dashboard.Suppliers.Negociated.RenegociationType.Template.Label"
          }
        }
      },
      "negotiated": {
        "negotiatedviews": {
          "alerts": {
            "error": {
              "title": "Dashboard.Suppliers.Negotiated.NegotiatedViews.Alerts.Error.Title"
            }
          }
        }
      },
      "noresult": "Dashboard.Suppliers.NoResult",
      "signed": {
        "buttons": {
          "add": {
            "label": "Dashboard.Suppliers.Signed.Buttons.Add.Label"
          }
        },
        "dialogs": {
          "create": {
            "title": "Dashboard.Suppliers.Signed.Dialogs.Create.Title"
          },
          "delete": {
            "message": "Dashboard.Suppliers.Signed.Dialogs.Delete.Message",
            "title": "Dashboard.Suppliers.Signed.Dialogs.Delete.Title"
          },
          "update": {
            "title": "Dashboard.Suppliers.Signed.Dialogs.Update.Title"
          },
          "view": {
            "title": "Dashboard.Suppliers.Signed.Dialogs.View.Title"
          }
        },
        "fields": {
          "file": {
            "label": "Dashboard.Suppliers.Signed.Fields.File.Label"
          },
          "name": {
            "label": "Dashboard.Suppliers.Signed.Fields.Name.Label"
          }
        },
        "label": "Dashboard.Suppliers.Signed.Label"
      },
      "supporting": {
        "buttons": {
          "create": {
            "label": "Dashboard.Suppliers.Supporting.Buttons.Create.Label"
          }
        },
        "dialogs": {
          "create": {
            "title": "Dashboard.Suppliers.Supporting.Dialogs.Create.Title"
          },
          "delete": {
            "message": "Dashboard.Suppliers.Supporting.Dialogs.Delete.Message",
            "title": "Dashboard.Suppliers.Supporting.Dialogs.Delete.Title"
          },
          "update": {
            "title": "Dashboard.Suppliers.Supporting.Dialogs.Update.Title"
          },
          "view": {
            "title": "Dashboard.Suppliers.Supporting.Dialogs.View.Title"
          }
        },
        "document": {
          "dialogs": {
            "create": {
              "title": "Dashboard.Suppliers.Supporting.Document.Dialogs.Create.Title"
            },
            "delete": {
              "message": "Dashboard.Suppliers.Supporting.Document.Dialogs.Delete.Message",
              "title": "Dashboard.Suppliers.Supporting.Document.Dialogs.Delete.Title"
            }
          },
          "fields": {
            "file": {
              "label": "Dashboard.Suppliers.Supporting.Document.Fields.File.Label"
            }
          }
        },
        "fields": {
          "name": {
            "label": "Dashboard.Suppliers.Supporting.Fields.Name.Label"
          }
        },
        "label": "Dashboard.Suppliers.Supporting.Label"
      },
      "tabs": {
        "contracts": "Dashboard.Suppliers.Tabs.Contracts",
        "expertteam": "Dashboard.Suppliers.Tabs.ExpertTeam"
      }
    }
  },
  "dashboardview": {
    "customfilters": {
      "novalue": "DashboardView.CustomFilters.NoValue"
    }
  },
  "department": {
    "buttons": {
      "add": "Department.Buttons.Add"
    },
    "dialogs": {
      "create": {
        "title": "Department.Dialogs.Create.Title"
      },
      "delete": {
        "message": "Department.Dialogs.Delete.Message",
        "title": "Department.Dialogs.Delete.Title"
      },
      "update": {
        "title": "Department.Dialogs.Update.Title"
      }
    },
    "fields": {
      "externalid": {
        "label": "Department.Fields.ExternalId.Label",
        "required": "Department.Fields.ExternalId.Required"
      },
      "name": {
        "label": "Department.Fields.Name.Label",
        "required": "Department.Fields.Name.Required"
      },
      "notificationmode": {
        "expert": "Department.Fields.NotificationMode.Expert",
        "label": "Department.Fields.NotificationMode.Label",
        "projectmanager": "Department.Fields.NotificationMode.ProjectManager",
        "required": "Department.Fields.NotificationMode.Required"
      }
    },
    "noresult": "Department.NoResult"
  },
  "dialogs": {
    "teams": {
      "add": {
        "title": "Dialogs.Teams.Add.Title"
      },
      "delete": {
        "message": "Dialogs.Teams.Delete.Message",
        "title": "Dialogs.Teams.Delete.Title"
      },
      "update": {
        "title": "Dialogs.Teams.Update.Title"
      }
    },
    "tenants": {
      "add": {
        "title": "Dialogs.Tenants.Add.Title"
      },
      "update": {
        "title": "Dialogs.Tenants.Update.Title"
      }
    }
  },
  "document": {
    "buttons": {
      "show": {
        "all": "Document.Buttons.Show.All",
        "less": "Document.Buttons.Show.Less"
      }
    },
    "card": {
      "amendment": "Document.Card.Amendment",
      "createdon": "Document.Card.CreatedOn",
      "editedon": "Document.Card.EditedOn",
      "negotiationtype": "Document.Card.NegotiationType",
      "previousversions": "Document.Card.PreviousVersions"
    },
    "fields": {
      "status": {
        "0": "Document.Fields.Status.0",
        "1": "Document.Fields.Status.1",
        "2": "Document.Fields.Status.2",
        "3": "Document.Fields.Status.3",
        "4": "Document.Fields.Status.4",
        "5": "Document.Fields.Status.5",
        "6": "Document.Fields.Status.6",
        "7": "Document.Fields.Status.7",
        "unknown": "Document.Fields.Status.Unknown"
      }
    },
    "table": {
      "header": {
        "createdon": "Document.Table.Header.CreatedOn",
        "department": "Document.Table.Header.Department",
        "editedon": "Document.Table.Header.EditedOn",
        "id": "Document.Table.Header.Id",
        "productmanager": "Document.Table.Header.ProductManager"
      }
    }
  },
  "documentstatsview": {
    "adddocumentmodal": {
      "buttons": {
        "save": {
          "label": "DocumentStatsView.AddDocumentModal.Buttons.Save.Label"
        }
      }
    }
  },
  "documentsstatsview": {
    "buttons": {
      "adddocument": {
        "label": "DocumentsStatsView.Buttons.AddDocument.Label"
      }
    },
    "info": {
      "nodocuments": "DocumentsStatsView.Info.NoDocuments"
    },
    "sections": {
      "documentssubmitted": {
        "label": "DocumentsStatsView.Sections.DocumentsSubmitted.Label"
      },
      "documentstovalidate": {
        "label": "DocumentsStatsView.Sections.DocumentsToValidate.Label"
      }
    }
  },
  "editor": {
    "clause": {
      "fields": {
        "explanations": {
          "label": "Editor.Clause.Fields.Explanations.Label",
          "placeholder": "Editor.Clause.Fields.Explanations.Placeholder"
        },
        "faq": {
          "content": {
            "label": "Editor.Clause.Fields.Faq.Content.Label"
          },
          "expertise": {
            "label": "Editor.Clause.Fields.Faq.Expertise.Label"
          },
          "language": {
            "label": "Editor.Clause.Fields.Faq.Language.Label"
          }
        },
        "mandatory": {
          "label": "Editor.Clause.Fields.Mandatory.Label"
        },
        "name": {
          "label": "Editor.Clause.Fields.Name.Label",
          "required": "Editor.Clause.Fields.Name.Required"
        },
        "question": {
          "label": "Editor.Clause.Fields.Question.Label",
          "required": "Editor.Clause.Fields.Question.Required"
        },
        "recursive": {
          "label": "Editor.Clause.Fields.Recursive.Label"
        },
        "type": {
          "label": "Editor.Clause.Fields.Type.Label",
          "mcq": {
            "label": "Editor.Clause.Fields.Type.Mcq.Label"
          },
          "scq": {
            "label": "Editor.Clause.Fields.Type.Scq.Label"
          }
        }
      }
    },
    "comment": {
      "message": {
        "deleted": "Editor.Comment.Message.Deleted",
        "label": "Editor.Comment.Message.Label",
        "placeholder": "Editor.Comment.Message.Placeholder"
      }
    },
    "condition": {
      "manage": {
        "label": "Editor.Condition.Manage.Label"
      }
    },
    "dialog": {
      "add": {
        "variable": {
          "linked": {
            "title": "Editor.Dialog.Add.Variable.Linked.Title"
          }
        }
      },
      "delete": {
        "clause": {
          "content": "Editor.Dialog.Delete.Clause.Content",
          "qdata": {
            "content": "Editor.Dialog.Delete.Clause.Qdata.Content"
          },
          "title": "Editor.Dialog.Delete.Clause.Title"
        },
        "variable": {
          "content": "Editor.Dialog.Delete.Variable.Content",
          "title": "Editor.Dialog.Delete.Variable.Title"
        },
        "variant": {
          "content": "Editor.Dialog.Delete.Variant.Content",
          "title": "Editor.Dialog.Delete.Variant.Title"
        }
      }
    },
    "image": {
      "fromcomputer": {
        "label": "Editor.Image.FromComputer.Label"
      },
      "frominternet": {
        "invalidformat": "Editor.Image.FromInternet.InvalidFormat",
        "label": "Editor.Image.FromInternet.Label",
        "required": "Editor.Image.FromInternet.Required"
      },
      "invalidformat": "Editor.Image.InvalidFormat"
    },
    "negotiation": {
      "bot": {
        "messages": {
          "clause": {
            "invalidated": "Editor.Negotiation.Bot.Messages.Clause.Invalidated",
            "modified": "Editor.Negotiation.Bot.Messages.Clause.Modified",
            "validated": "Editor.Negotiation.Bot.Messages.Clause.Validated"
          }
        }
      },
      "edit": {
        "clause": "Editor.Negotiation.Edit.Clause"
      },
      "faq": {
        "delete": "Editor.Negotiation.FAQ.Delete"
      },
      "see": {
        "messages": "Editor.Negotiation.See.Messages"
      }
    },
    "notebook": {
      "save": {
        "success": "Editor.Notebook.Save.Success"
      }
    },
    "numbering": {
      "list": {
        "continue": "Editor.Numbering.List.Continue",
        "new": "Editor.Numbering.List.New"
      },
      "multilevel": {
        "disable": "Editor.Numbering.Multilevel.Disable",
        "enable": "Editor.Numbering.Multilevel.Enable"
      },
      "prefix": {
        "label": "Editor.Numbering.Prefix.Label",
        "suffix": {
          "set": {
            "label": "Editor.Numbering.Prefix.Suffix.Set.Label"
          }
        }
      },
      "set": {
        "value": "Editor.Numbering.Set.Value"
      },
      "suffix": {
        "label": "Editor.Numbering.Suffix.Label",
        "list": {
          "label": "Editor.Numbering.Suffix.List.Label"
        }
      },
      "type": {
        "alpha": {
          "lower": "Editor.Numbering.Type.Alpha.Lower",
          "upper": "Editor.Numbering.Type.Alpha.Upper"
        },
        "article": "Editor.Numbering.Type.Article",
        "label": "Editor.Numbering.Type.Label",
        "none": "Editor.Numbering.Type.None",
        "numerical": "Editor.Numbering.Type.Numerical",
        "roman": {
          "lower": "Editor.Numbering.Type.Roman.Lower",
          "upper": "Editor.Numbering.Type.Roman.Upper"
        }
      }
    },
    "page": {
      "break": {
        "insert": "Editor.Page.Break.Insert",
        "label": "Editor.Page.Break.Label"
      }
    },
    "save": {
      "failure": "Editor.Save.Failure"
    },
    "section": {
      "options": {
        "label": "Editor.Section.Options.Label"
      },
      "page": {
        "close": {
          "label": "Editor.Section.Page.Close.Label"
        },
        "even": {
          "label": "Editor.Section.Page.Even.Label",
          "odd": {
            "different": {
              "label": "Editor.Section.Page.Even.Odd.Different.Label"
            }
          }
        },
        "first": {
          "different": {
            "label": "Editor.Section.Page.First.Different.Label"
          },
          "even": {
            "odd": {
              "different": {
                "label": "Editor.Section.Page.First.Even.Odd.Different.Label"
              }
            }
          },
          "label": "Editor.Section.Page.First.Label"
        },
        "headers": {
          "options": {
            "label": "Editor.Section.Page.Headers.Options.Label"
          }
        },
        "number": {
          "label": "Editor.Section.Page.Number.Label",
          "toggle": {
            "label": "Editor.Section.Page.Number.Toggle.Label"
          }
        },
        "odd": {
          "label": "Editor.Section.Page.Odd.Label"
        },
        "other": {
          "label": "Editor.Section.Page.Other.Label"
        }
      },
      "same": {
        "label": "Editor.Section.Same.Label"
      }
    },
    "signature": {
      "addslot": {
        "label": "Editor.Signature.AddSlot.Label"
      },
      "button": {
        "add": {
          "checkbox": "Editor.Signature.Button.Add.Checkbox",
          "date": "Editor.Signature.Button.Add.Date",
          "firstname": "Editor.Signature.Button.Add.FirstName",
          "freetext": "Editor.Signature.Button.Add.FreeText",
          "initials": "Editor.Signature.Button.Add.Initials",
          "lastname": "Editor.Signature.Button.Add.LastName",
          "signature": "Editor.Signature.Button.Add.Signature"
        },
        "remove": {
          "slot": "Editor.Signature.Button.Remove.Slot"
        }
      },
      "orientation": {
        "description": "Editor.Signature.Orientation.Description",
        "label": "Editor.Signature.Orientation.Label"
      },
      "role": {
        "target": {
          "description": "Editor.Signature.Role.Target.Description",
          "label": "Editor.Signature.Role.Target.Label"
        }
      },
      "settings": {
        "label": "Editor.Signature.Settings.Label"
      },
      "slot": {
        "label": "Editor.Signature.Slot.Label"
      },
      "type": {
        "checkbox": "Editor.Signature.Type.Checkbox",
        "firstname": "Editor.Signature.Type.FirstName",
        "freetext": "Editor.Signature.Type.FreeText",
        "horizontal": "Editor.Signature.Type.Horizontal",
        "initials": "Editor.Signature.Type.Initials",
        "lastname": "Editor.Signature.Type.LastName",
        "signature": "Editor.Signature.Type.Signature",
        "vertical": "Editor.Signature.Type.Vertical"
      }
    },
    "title": "Editor.Title",
    "toolbar": {
      "command": {
        "align": {
          "center": "Editor.Toolbar.Command.Align.Center",
          "justify": "Editor.Toolbar.Command.Align.Justify",
          "left": "Editor.Toolbar.Command.Align.Left",
          "right": "Editor.Toolbar.Command.Align.Right"
        },
        "backgroundcolor": "Editor.Toolbar.Command.BackgroundColor",
        "bold": "Editor.Toolbar.Command.Bold",
        "clause": {
          "delete": "Editor.Toolbar.Command.Clause.Delete",
          "mcq": {
            "add": "Editor.Toolbar.Command.Clause.Mcq.Add"
          },
          "qdata": {
            "add": "Editor.Toolbar.Command.Clause.Qdata.Add"
          },
          "scq": {
            "add": "Editor.Toolbar.Command.Clause.Scq.Add"
          }
        },
        "clearformat": "Editor.Toolbar.Command.ClearFormat",
        "color": {
          "clear": "Editor.Toolbar.Command.Color.Clear"
        },
        "comment": {
          "add": "Editor.Toolbar.Command.Comment.Add"
        },
        "fontfamily": "Editor.Toolbar.Command.FontFamily",
        "fontsize": "Editor.Toolbar.Command.FontSize",
        "format": {
          "painter": "Editor.Toolbar.Command.Format.Painter"
        },
        "headerfooter": {
          "toogle": "Editor.Toolbar.Command.HeaderFooter.Toogle"
        },
        "heading": "Editor.Toolbar.Command.Heading",
        "image": {
          "insert": "Editor.Toolbar.Command.Image.Insert"
        },
        "italic": "Editor.Toolbar.Command.Italic",
        "line": {
          "above": "Editor.Toolbar.Command.Line.Above",
          "below": "Editor.Toolbar.Command.Line.Below",
          "height": "Editor.Toolbar.Command.Line.Height"
        },
        "list": {
          "ordered": "Editor.Toolbar.Command.List.Ordered",
          "unordered": "Editor.Toolbar.Command.List.Unordered"
        },
        "margin": {
          "bottom": "Editor.Toolbar.Command.Margin.Bottom",
          "label": "Editor.Toolbar.Command.Margin.Label",
          "left": "Editor.Toolbar.Command.Margin.Left",
          "right": "Editor.Toolbar.Command.Margin.Right",
          "top": "Editor.Toolbar.Command.Margin.Top"
        },
        "redo": "Editor.Toolbar.Command.Redo",
        "strike": "Editor.Toolbar.Command.Strike",
        "table": {
          "cell": "Editor.Toolbar.Command.Table.Cell",
          "cellparam": {
            "border": {
              "dashed": "Editor.Toolbar.Command.Table.CellParam.Border.Dashed",
              "dotted": "Editor.Toolbar.Command.Table.CellParam.Border.Dotted",
              "enabled": "Editor.Toolbar.Command.Table.CellParam.Border.Enabled",
              "none": "Editor.Toolbar.Command.Table.CellParam.Border.None",
              "settings": "Editor.Toolbar.Command.Table.CellParam.Border.Settings",
              "solid": "Editor.Toolbar.Command.Table.CellParam.Border.Solid",
              "style": "Editor.Toolbar.Command.Table.CellParam.Border.Style",
              "toggle": {
                "bottom": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Bottom",
                "left": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Left",
                "right": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Right",
                "top": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Top"
              }
            },
            "verticalalign": "Editor.Toolbar.Command.Table.CellParam.VerticalAlign"
          },
          "column": "Editor.Toolbar.Command.Table.Column",
          "delete": "Editor.Toolbar.Command.Table.Delete",
          "header": "Editor.Toolbar.Command.Table.Header",
          "insert": "Editor.Toolbar.Command.Table.Insert",
          "row": "Editor.Toolbar.Command.Table.Row"
        },
        "textcolor": "Editor.Toolbar.Command.TextColor",
        "underline": "Editor.Toolbar.Command.Underline",
        "undo": "Editor.Toolbar.Command.Undo",
        "variable": {
          "add": "Editor.Toolbar.Command.Variable.Add",
          "linked": {
            "add": "Editor.Toolbar.Command.Variable.Linked.Add"
          }
        },
        "variant": {
          "add": "Editor.Toolbar.Command.Variant.Add"
        }
      }
    },
    "track": {
      "changes": {
        "accepted": {
          "add": {
            "notebook": {
              "modal": {
                "title": "Editor.Track.Changes.Accepted.Add.NoteBook.Modal.Title"
              }
            }
          },
          "btn": {
            "cancel": {
              "modification": "Editor.Track.Changes.Accepted.Btn.Cancel.Modification"
            },
            "save": {
              "add": {
                "notes": "Editor.Track.Changes.Accepted.Btn.Save.Add.Notes"
              },
              "without": {
                "notes": "Editor.Track.Changes.Accepted.Btn.Save.Without.Notes"
              }
            }
          }
        }
      }
    },
    "trackchanges": {
      "annotation": {
        "label": "Editor.TrackChanges.Annotation.Label"
      },
      "deleted": {
        "label": "Editor.TrackChanges.Deleted.Label"
      },
      "deletion": {
        "label": "Editor.TrackChanges.Deletion.Label"
      },
      "inserted": {
        "label": "Editor.TrackChanges.Inserted.Label"
      },
      "insertion": {
        "label": "Editor.TrackChanges.Insertion.Label"
      },
      "modification": {
        "label": "Editor.TrackChanges.Modification.Label"
      },
      "replaced": {
        "label": "Editor.TrackChanges.Replaced.Label"
      },
      "replacedwith": {
        "label": "Editor.TrackChanges.ReplacedWith.Label"
      }
    },
    "variable": {
      "fields": {
        "defaultvalue": {
          "label": "Editor.Variable.Fields.DefaultValue.Label"
        },
        "linked": {
          "clause": {
            "label": "Editor.Variable.Fields.Linked.Clause.Label"
          },
          "variable": {
            "label": "Editor.Variable.Fields.Linked.Variable.Label"
          },
          "variant": {
            "label": "Editor.Variable.Fields.Linked.Variant.Label"
          }
        },
        "name": {
          "label": "Editor.Variable.Fields.Name.Label",
          "required": "Editor.Variable.Fields.Name.Required"
        },
        "payload": {
          "datetime": {
            "format": {
              "example": "Editor.Variable.Fields.Payload.DateTime.Format.Example",
              "full": "Editor.Variable.Fields.Payload.DateTime.Format.Full",
              "label": "Editor.Variable.Fields.Payload.DateTime.Format.Label",
              "long": "Editor.Variable.Fields.Payload.DateTime.Format.Long",
              "medium": "Editor.Variable.Fields.Payload.DateTime.Format.Medium",
              "short": "Editor.Variable.Fields.Payload.DateTime.Format.Short"
            }
          },
          "label": "Editor.Variable.Fields.Payload.Label",
          "listitem": {
            "format": {
              "conjunction": "Editor.Variable.Fields.Payload.ListItem.Format.Conjunction",
              "disjunction": "Editor.Variable.Fields.Payload.ListItem.Format.Disjunction",
              "example": "Editor.Variable.Fields.Payload.ListItem.Format.Example",
              "label": "Editor.Variable.Fields.Payload.ListItem.Format.Label",
              "unit": "Editor.Variable.Fields.Payload.ListItem.Format.Unit"
            },
            "label": "Editor.Variable.Fields.Payload.ListItem.Label",
            "required": "Editor.Variable.Fields.Payload.ListItem.Required",
            "style": {
              "label": "Editor.Variable.Fields.Payload.ListItem.Style.Label",
              "long": "Editor.Variable.Fields.Payload.ListItem.Style.Long",
              "narrow": "Editor.Variable.Fields.Payload.ListItem.Style.Narrow",
              "short": "Editor.Variable.Fields.Payload.ListItem.Style.Short"
            }
          },
          "number": {
            "currency": {
              "label": "Editor.Variable.Fields.Payload.Number.Currency.Label"
            },
            "format": {
              "currency": "Editor.Variable.Fields.Payload.Number.Format.Currency",
              "decimal": "Editor.Variable.Fields.Payload.Number.Format.Decimal",
              "example": "Editor.Variable.Fields.Payload.Number.Format.Example",
              "label": "Editor.Variable.Fields.Payload.Number.Format.Label",
              "percent": "Editor.Variable.Fields.Payload.Number.Format.Percent"
            },
            "maximumfractiondigits": {
              "label": "Editor.Variable.Fields.Payload.Number.MaximumFractionDigits.Label",
              "required": "Editor.Variable.Fields.Payload.Number.MaximumFractionDigits.Required"
            },
            "style": {
              "code": "Editor.Variable.Fields.Payload.Number.Style.Code",
              "label": "Editor.Variable.Fields.Payload.Number.Style.Label",
              "name": "Editor.Variable.Fields.Payload.Number.Style.Name",
              "symbol": "Editor.Variable.Fields.Payload.Number.Style.Symbol"
            }
          },
          "required": {
            "": "Editor.Variable.Fields.Payload.Required."
          },
          "select": {
            "explanation": "Editor.Variable.Fields.Payload.Select.Explanation"
          }
        },
        "placeholder": {
          "label": "Editor.Variable.Fields.Placeholder.Label"
        },
        "required": {
          "label": "Editor.Variable.Fields.Required.Label"
        },
        "type": {
          "calculated": {
            "label": "Editor.Variable.Fields.Type.Calculated.Label"
          },
          "date": {
            "label": "Editor.Variable.Fields.Type.Date.Label"
          },
          "datespan": {
            "label": "Editor.Variable.Fields.Type.DateSpan.Label"
          },
          "email": {
            "label": "Editor.Variable.Fields.Type.Email.Label"
          },
          "image": {
            "label": "Editor.Variable.Fields.Type.Image.Label"
          },
          "label": "Editor.Variable.Fields.Type.Label",
          "linked": {
            "label": "Editor.Variable.Fields.Type.Linked.Label"
          },
          "list": {
            "label": "Editor.Variable.Fields.Type.List.Label"
          },
          "multiline": {
            "label": "Editor.Variable.Fields.Type.Multiline.Label"
          },
          "number": {
            "label": "Editor.Variable.Fields.Type.Number.Label"
          },
          "percent": {
            "label": "Editor.Variable.Fields.Type.Percent.Label"
          },
          "phone": {
            "label": "Editor.Variable.Fields.Type.Phone.Label"
          },
          "selectlist": {
            "label": "Editor.Variable.Fields.Type.SelectList.Label"
          },
          "text": {
            "label": "Editor.Variable.Fields.Type.Text.Label"
          },
          "time": {
            "label": "Editor.Variable.Fields.Type.Time.Label"
          },
          "timespan": {
            "label": "Editor.Variable.Fields.Type.TimeSpan.Label"
          }
        }
      }
    },
    "variant": {
      "fields": {
        "name": {
          "label": "Editor.Variant.Fields.Name.Label",
          "required": "Editor.Variant.Fields.Name.Required"
        }
      }
    }
  },
  "errors": {
    "blockdocumentsarerequired": "Errors.BlockDocumentsAreRequired",
    "campaignalreadyexists": "Errors.CampaignAlreadyExists",
    "contracthascommentsandneedsconfirmation": "Errors.ContractHasCommentsAndNeedsConfirmation",
    "contracthascommentsandnextworkflowstepissign": "Errors.ContractHasCommentsAndNextWorkflowStepIsSign",
    "contracthasnopdfcontent": "Errors.ContractHasNoPdfContent",
    "contractsignaturecancelfailed": "Errors.ContractSignatureCancelFailed",
    "contractsignaturesendfailed": "Errors.ContractSignatureSendFailed",
    "contractualpacknegociatedtemplatenotfound": "Errors.ContractualPackNegociatedTemplateNotFound",
    "contractualpacksigneddocumentnotfound": "Errors.ContractualPackSignedDocumentNotFound",
    "contractualpacksupportingdocumentalreadyexists": "Errors.ContractualPackSupportingDocumentAlreadyExists",
    "contractualpacksupportingdocumentnotfound": "Errors.ContractualPackSupportingDocumentNotFound",
    "departmentalreadyexists": "Errors.DepartmentAlreadyExists",
    "departmentnotempty": "Errors.DepartmentNotEmpty",
    "departmentnotfound": "Errors.DepartmentNotFound",
    "departmentrootnotfound": "Errors.DepartmentRootNotFound",
    "documenttemplateidisrequired": "Errors.DocumentTemplateIdIsRequired",
    "editoroccured": "Errors.EditorOccured",
    "erroroccured": "Errors.ErrorOccured",
    "expertalreadyexists": "Errors.ExpertAlreadyExists",
    "expertisealreadyexists": "Errors.ExpertiseAlreadyExists",
    "expertisenotfound": "Errors.ExpertiseNotFound",
    "foldernotfound": "Errors.FolderNotFound",
    "invalidemailpassword": "Errors.InvalidEmailPassword",
    "multisignaturemodal": {
      "submiterrors": {
        "documents": {
          "required": "Errors.MultiSignatureModal.SubmitErrors.Documents.Required"
        },
        "signatures": {
          "required": "Errors.MultiSignatureModal.SubmitErrors.Signatures.Required"
        }
      }
    },
    "networkerroroccured": "Errors.NetworkErrorOccured",
    "projectmanageralreadyexists": "Errors.ProjectManagerAlreadyExists",
    "projectmanagerisleader": "Errors.ProjectManagerIsLeader",
    "signatureproviderinfonotfound": "Errors.SignatureProviderInfoNotFound",
    "suppliersreferentialnotfound": "Errors.SuppliersReferentialNotFound",
    "supportingalreadyexists": "Errors.SupportingAlreadyExists",
    "supportingdocumentalreadyexists": "Errors.SupportingDocumentAlreadyExists",
    "supportingdocumentnotfound": "Errors.SupportingDocumentNotFound",
    "supportingelementnotfound": "Errors.SupportingElementNotFound",
    "supportinghasdocument": "Errors.SupportingHasDocument",
    "supportingnotfound": "Errors.SupportingNotFound",
    "teamalreadyexists": "Errors.TeamAlreadyExists",
    "tenantadministratoralreadyexists": "Errors.TenantAdministratorAlreadyExists",
    "tenantalreadyexists": "Errors.TenantAlreadyExists",
    "userhasnopassword": "Errors.UserHasNoPassword",
    "userhasnotenants": "Errors.UserHasNoTenants",
    "userlockedout": "Errors.UserLockedOut",
    "usernotfound": "Errors.UserNotFound",
    "workflowcontributorsnotvalidated": "Errors.WorkflowContributorsNotValidated",
    "workflowstepcontributornotfound": "Errors.WorkflowStepContributorNotFound"
  },
  "expert": {
    "buttons": {
      "add": "Expert.Buttons.Add"
    }
  },
  "expertise": {
    "buttons": {
      "add": "Expertise.Buttons.Add"
    },
    "dialogs": {
      "create": {
        "title": "Expertise.Dialogs.Create.Title"
      },
      "delete": {
        "message": "Expertise.Dialogs.Delete.Message",
        "title": "Expertise.Dialogs.Delete.Title"
      },
      "update": {
        "title": "Expertise.Dialogs.Update.Title"
      }
    },
    "fields": {
      "expert": {
        "label": "Expertise.Fields.Expert.Label",
        "required": "Expertise.Fields.Expert.Required"
      },
      "name": {
        "label": "Expertise.Fields.Name.Label",
        "required": "Expertise.Fields.Name.Required"
      },
      "notificationmode": {
        "expert": "Expertise.Fields.NotificationMode.Expert",
        "label": "Expertise.Fields.NotificationMode.Label",
        "projectmanager": "Expertise.Fields.NotificationMode.ProjectManager",
        "required": "Expertise.Fields.NotificationMode.Required"
      }
    },
    "noresult": "Expertise.NoResult"
  },
  "export": {
    "all": {
      "csv": "Export.All.CSV"
    },
    "business": {
      "csv": "Export.Business.CSV"
    }
  },
  "filefield": {
    "value": "FileField.Value"
  },
  "formview": {
    "allow": {
      "supplier": {
        "buttons": {
          "allowchanges": "FormView.Allow.Supplier.Buttons.AllowChanges",
          "makechanges": "FormView.Allow.Supplier.Buttons.MakeChanges"
        }
      },
      "suppliertoedit": "FormView.Allow.SupplierToEdit"
    },
    "commercialcontacts": {
      "accordionitem": {
        "label": "FormView.CommercialContacts.AccordionItem.Label"
      },
      "contacts": {
        "button": {
          "add": "FormView.CommercialContacts.Contacts.Button.Add"
        }
      },
      "modals": {
        "adduser": "FormView.CommercialContacts.Modals.AddUser",
        "delete": "FormView.CommercialContacts.Modals.Delete"
      }
    },
    "contractnegotiator": {
      "accordionitem": {
        "label": "FormView.ContractNegotiator.AccordionItem.Label"
      }
    },
    "documenttoimport": {
      "accordionitem": {
        "label": "FormView.DocumentToImport.AccordionItem.Label"
      },
      "deletedialog": {
        "confirm": "FormView.DocumentToImport.DeleteDialog.Confirm",
        "title": "FormView.DocumentToImport.DeleteDialog.Title"
      },
      "document": {
        "import": {
          "date": "FormView.DocumentToImport.Document.Import.Date"
        }
      },
      "documentuploadrow": {
        "button": {
          "donthavethisfile": "FormView.DocumentToImport.DocumentUploadRow.Button.DontHaveThisFile",
          "download": "FormView.DocumentToImport.DocumentUploadRow.Button.Download",
          "ifoundthedocument": "FormView.DocumentToImport.DocumentUploadRow.Button.IFoundTheDocument",
          "import": "FormView.DocumentToImport.DocumentUploadRow.Button.Import"
        },
        "buttondonthavethisfile": "FormView.DocumentToImport.DocumentUploadRow.ButtonDontHaveThisFile",
        "declaredmissing": "FormView.DocumentToImport.DocumentUploadRow.DeclaredMissing",
        "fields": {
          "document": "FormView.DocumentToImport.DocumentUploadRow.Fields.Document"
        },
        "info": {
          "maxsize": "FormView.DocumentToImport.DocumentUploadRow.Info.MaxSize"
        }
      },
      "uploaddialog": {
        "title": "FormView.DocumentToImport.UploadDialog.Title"
      }
    },
    "documenttovalidate": {
      "accordionitem": {
        "label": "FormView.DocumentToValidate.AccordionItem.Label"
      },
      "documentnotifications": "FormView.DocumentToValidate.DocumentNotifications"
    },
    "informations": {
      "accordion": {
        "description": "FormView.Informations.Accordion.Description"
      },
      "accordionitem": {
        "label": "FormView.Informations.AccordionItem.Label"
      },
      "buttons": {
        "contactdetails": "FormView.Informations.Buttons.ContactDetails",
        "correctinfo": "FormView.Informations.Buttons.CorrectInfo",
        "correctmycontacts": "FormView.Informations.Buttons.CorrectMyContacts",
        "editdocuments": "FormView.Informations.Buttons.EditDocuments",
        "loading": "FormView.Informations.Buttons.Loading",
        "validate": "FormView.Informations.Buttons.Validate",
        "validatecontacts": "FormView.Informations.Buttons.ValidateContacts",
        "validatedocuments": "FormView.Informations.Buttons.ValidateDocuments"
      },
      "fields": {
        "address1": {
          "label": "FormView.Informations.Fields.Address1.Label"
        },
        "address2": {
          "label": "FormView.Informations.Fields.Address2.Label"
        },
        "calavieclaire2019": {
          "label": "FormView.Informations.Fields.CALavieclaire2019.Label"
        },
        "calavieclaire2020": {
          "label": "FormView.Informations.Fields.CALavieclaire2020.Label"
        },
        "calavieclaire2021": {
          "label": "FormView.Informations.Fields.CALavieclaire2021.Label"
        },
        "calavieclaire2022": {
          "label": "FormView.Informations.Fields.CALavieclaire2022.Label"
        },
        "cavendor2019": {
          "label": "FormView.Informations.Fields.CAVendor2019.Label"
        },
        "cavendor2020": {
          "label": "FormView.Informations.Fields.CAVendor2020.Label"
        },
        "cavendor2021": {
          "label": "FormView.Informations.Fields.CAVendor2021.Label"
        },
        "cavendor2022": {
          "label": "FormView.Informations.Fields.CAVendor2022.Label"
        },
        "city": {
          "label": "FormView.Informations.Fields.City.Label"
        },
        "cityofregistration": {
          "label": "FormView.Informations.Fields.CityOfRegistration.Label"
        },
        "country": {
          "label": "FormView.Informations.Fields.Country.Label"
        },
        "dateofdebut": {
          "label": "FormView.Informations.Fields.DateOfDebut.Label"
        },
        "employees": {
          "label": "FormView.Informations.Fields.Employees.Label"
        },
        "financialdependence": {
          "label": "FormView.Informations.Fields.FinancialDependence.Label"
        },
        "header": "FormView.Informations.Fields.Header",
        "language": {
          "english": {
            "label": "FormView.Informations.Fields.Language.English.Label"
          },
          "french": {
            "label": "FormView.Informations.Fields.Language.French.Label"
          },
          "label": "FormView.Informations.Fields.Language.Label"
        },
        "legalrepresentativefirstname": {
          "label": "FormView.Informations.Fields.LegalRepresentativeFirstName.Label"
        },
        "legalrepresentativefunction": {
          "label": "FormView.Informations.Fields.LegalRepresentativeFunction.Label"
        },
        "legalrepresentativename": {
          "label": "FormView.Informations.Fields.LegalRepresentativeName.Label"
        },
        "legalrepresentativetitle": {
          "label": "FormView.Informations.Fields.LegalRepresentativeTitle.Label",
          "miss": {
            "label": "FormView.Informations.Fields.LegalRepresentativeTitle.Miss.Label"
          },
          "mister": {
            "label": "FormView.Informations.Fields.LegalRepresentativeTitle.Mister.Label"
          }
        },
        "postalcode": {
          "label": "FormView.Informations.Fields.PostalCode.Label"
        },
        "registrationnumber": {
          "label": "FormView.Informations.Fields.RegistrationNumber.Label"
        },
        "socialcapital": {
          "label": "FormView.Informations.Fields.SocialCapital.Label"
        },
        "suppliername": {
          "label": "FormView.Informations.Fields.SupplierName.Label"
        }
      },
      "laviesales": {
        "maincampaignyear": {
          "fields": {
            "label": "FormView.Informations.LavieSales.MainCampaignYear.Fields.Label"
          }
        },
        "secondarycampaignyear": {
          "fields": {
            "label": "FormView.Informations.LavieSales.SecondaryCampaignYear.Fields.Label"
          }
        }
      },
      "requiredfields": "FormView.Informations.RequiredFields",
      "suppliersales": {
        "maincampaignyear": {
          "fields": {
            "label": "FormView.Informations.SupplierSales.MainCampaignYear.Fields.Label"
          }
        },
        "secondarycampaignyear": {
          "fields": {
            "label": "FormView.Informations.SupplierSales.SecondaryCampaignYear.Fields.Label"
          }
        }
      }
    },
    "signatorycontacts": {
      "accordionitem": {
        "label": "FormView.SignatoryContacts.AccordionItem.Label"
      },
      "contactsignature": {
        "add": {
          "button": "FormView.SignatoryContacts.ContactSignature.Add.Button"
        }
      }
    },
    "updatecontact": {
      "fields": {
        "civility": "FormView.UpdateContact.Fields.Civility"
      }
    },
    "updatenegotiator": {
      "contact": {
        "modal": {
          "label": "formView.UpdateNegotiator.Contact.Modal.Label"
        }
      }
    },
    "uploadusagewarning": {
      "label": "FormView.UploadUsageWarning.Label"
    },
    "usagewarning": {
      "label": "FormView.UsageWarning.Label"
    },
    "usagewarningfilesent": {
      "label": "FormView.UsageWarningFileSent.Label"
    }
  },
  "generic": {
    "fields": {
      "date": {
        "maxtoday": "Generic.Fields.Date.MaxToday"
      },
      "invalidformat": "Generic.Fields.InvalidFormat",
      "mustbepositive": "Generic.Fields.MustBePositive",
      "required": "Generic.Fields.Required"
    }
  },
  "header": {
    "menu": {
      "administration": "Header.Menu.Administration",
      "dashboard": "Header.Menu.Dashboard",
      "help": "Header.Menu.Help",
      "parameters": "Header.Menu.Parameters"
    }
  },
  "headers": {
    "headeradministrators": {
      "administration": "Headers.HeaderAdministrators.Administration",
      "dashboard": "Headers.HeaderAdministrators.Dashboard",
      "help": "Headers.HeaderAdministrators.Help",
      "statistics": "Headers.HeaderAdministrators.Statistics"
    }
  },
  "helpcenter": {
    "document": {
      "buttons": {
        "add": "HelpCenter.Document.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "HelpCenter.Document.Dialogs.Create.Title"
        },
        "delete": {
          "message": "HelpCenter.Document.Dialogs.Delete.Message",
          "title": "HelpCenter.Document.Dialogs.Delete.Title"
        },
        "update": {
          "title": "HelpCenter.Document.Dialogs.Update.Title"
        }
      },
      "fields": {
        "file": {
          "invalidformat": "HelpCenter.Document.Fields.File.InvalidFormat",
          "label": "HelpCenter.Document.Fields.File.Label",
          "required": "HelpCenter.Document.Fields.File.Required"
        },
        "name": {
          "label": "HelpCenter.Document.Fields.Name.Label"
        },
        "role": {
          "label": "HelpCenter.Document.Fields.Role.Label",
          "required": "HelpCenter.Document.Fields.Role.Required"
        }
      },
      "name": {
        "required": "HelpCenter.Document.Name.Required"
      },
      "role": {
        "everybody": "HelpCenter.Document.Role.Everybody"
      },
      "title": "HelpCenter.Document.Title"
    },
    "html": {
      "title": "HelpCenter.Html.Title"
    },
    "menu": {
      "help": "HelpCenter.Menu.Help",
      "releases": "HelpCenter.Menu.Releases"
    },
    "release": {
      "buttons": {
        "publish": "HelpCenter.Release.Buttons.Publish"
      },
      "external": {
        "title": "HelpCenter.Release.External.Title"
      },
      "internal": {
        "title": "HelpCenter.Release.Internal.Title"
      }
    }
  },
  "homepage": {
    "menu": {
      "contactus": "Homepage.Menu.ContactUs",
      "legalnotice": "Homepage.Menu.LegalNotice",
      "privacypolicy": "Homepage.Menu.PrivacyPolicy"
    },
    "welcome": {
      "message": "Homepage.Welcome.Message"
    }
  },
  "infomanagementview": {
    "campagne": {
      "title": "InfoManagementView.Campagne.Title"
    },
    "document": {
      "description": "InfoManagementView.Document.Description",
      "progressbar": {
        "title": "InfoManagementView.Document.ProgressBar.Title"
      },
      "title": "InfoManagementView.Document.Title"
    },
    "manage": {
      "contract": {
        "pack": {
          "description": "InfoManagementView.Manage.Contract.Pack.Description",
          "title": "InfoManagementView.Manage.Contract.Pack.Title"
        }
      }
    },
    "setup": {
      "campaign": {
        "description": "InfoManagementView.Setup.Campaign.Description",
        "title": "InfoManagementView.Setup.Campaign.Title"
      }
    },
    "title": "InfoManagementView.Title"
  },
  "languages": {
    "english": "Languages.English",
    "french": "Languages.French",
    "spanish": "Languages.Spanish"
  },
  "mangehelpview": {
    "save": {
      "success": "MangeHelpView.Save.Success"
    },
    "title": "MangeHelpView.Title"
  },
  "move": {
    "modal": {
      "empty": {
        "text": "Move.Modal.Empty.Text"
      },
      "root": {
        "text": "Move.Modal.Root.Text"
      }
    }
  },
  "movemodal": {
    "buttons": {
      "confirm": {
        "label": "MoveModal.Buttons.Confirm.Label"
      }
    },
    "description": {
      "text": "MoveModal.Description.Text"
    }
  },
  "movetemplatemodal": {
    "label": "MoveTemplateModal.Label"
  },
  "nexity": {
    "dashboard": {
      "card": {
        "batchnumber": {
          "label": "Nexity.Dashboard.Card.BatchNumber.Label"
        },
        "buyers": {
          "label": "Nexity.Dashboard.Card.Buyers.Label"
        },
        "projecttype": {
          "label": "Nexity.Dashboard.Card.ProjectType.Label"
        }
      }
    },
    "folders": {
      "acquirercontactdetails": "Nexity.Folders.AcquirerContactDetails",
      "formstocomplete": {
        "civilstate": "Nexity.Folders.FormsToComplete.CivilState",
        "familysituation": "Nexity.Folders.FormsToComplete.FamilySituation",
        "financing": "Nexity.Folders.FormsToComplete.Financing",
        "label": "Nexity.Folders.FormsToComplete.Label",
        "project": "Nexity.Folders.FormsToComplete.Project",
        "status": {
          "tocomplete": "Nexity.Folders.FormsToComplete.Status.ToComplete",
          "torectify": "Nexity.Folders.FormsToComplete.Status.ToRectify",
          "tovalidate": "Nexity.Folders.FormsToComplete.Status.ToValidate",
          "validated": "Nexity.Folders.FormsToComplete.Status.Validated"
        }
      },
      "vouchers": "Nexity.Folders.Vouchers"
    }
  },
  "notfound": {
    "message": "NotFound.Message",
    "title": "NotFound.Title"
  },
  "packages": {
    "negociated": {
      "buttons": {
        "add": "Packages.Negociated.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "Packages.Negociated.Dialogs.Create.Title"
        },
        "delete": {
          "message": "Packages.Negociated.Dialogs.Delete.Message",
          "title": "Packages.Negociated.Dialogs.Delete.Title"
        },
        "update": {
          "title": "Packages.Negociated.Dialogs.Update.Title"
        }
      },
      "fields": {
        "name": {
          "label": "Packages.Negociated.Fields.Name.Label",
          "required": "Packages.Negociated.Fields.Name.Required"
        }
      },
      "label": "Packages.Negociated.Label"
    },
    "noresult": "Packages.NoResult",
    "signed": {
      "buttons": {
        "add": "Packages.Signed.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "Packages.Signed.Dialogs.Create.Title"
        },
        "delete": {
          "message": "Packages.Signed.Dialogs.Delete.Message",
          "title": "Packages.Signed.Dialogs.Delete.Title"
        },
        "update": {
          "title": "Packages.Signed.Dialogs.Update.Title"
        },
        "view": {
          "title": "Packages.Signed.Dialogs.View.Title"
        }
      },
      "fields": {
        "file": {
          "invalidformat": "Packages.Signed.Fields.File.InvalidFormat",
          "label": "Packages.Signed.Fields.File.Label",
          "required": "Packages.Signed.Fields.File.Required"
        },
        "name": {
          "label": "Packages.Signed.Fields.Name.Label",
          "required": "Packages.Signed.Fields.Name.Required"
        }
      },
      "label": "Packages.Signed.Label"
    },
    "supporting": {
      "buttons": {
        "add": "Packages.Supporting.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "Packages.Supporting.Dialogs.Create.Title"
        },
        "delete": {
          "message": "Packages.Supporting.Dialogs.Delete.Message",
          "title": "Packages.Supporting.Dialogs.Delete.Title"
        },
        "update": {
          "title": "Packages.Supporting.Dialogs.Update.Title"
        }
      },
      "fields": {
        "name": {
          "label": "Packages.Supporting.Fields.Name.Label",
          "required": "Packages.Supporting.Fields.Name.Required"
        }
      },
      "label": "Packages.Supporting.Label"
    }
  },
  "picker": {
    "user": {
      "label": "Picker.User.Label"
    }
  },
  "referentials": {
    "actions": "Referentials.Actions",
    "addelement": "Referentials.AddElement",
    "createreferential": "Referentials.CreateReferential",
    "datakey": "Referentials.DataKey",
    "datavalue": "Referentials.DataValue",
    "deleteelement": "Referentials.DeleteElement",
    "deletereferential": "Referentials.DeleteReferential",
    "elementcount": "Referentials.ElementCount",
    "file": "Referentials.File",
    "name": "Referentials.Name",
    "noelementfound": "Referentials.NoElementFound",
    "noreferentialfound": "Referentials.NoReferentialFound",
    "seereferential": "Referentials.SeeReferential",
    "updateelement": "Referentials.UpdateElement",
    "updatereferential": "Referentials.UpdateReferential"
  },
  "searchbar": {
    "placeholder": "SearchBar.PlaceHolder"
  },
  "signin": {
    "button": "Signin.Button",
    "fields": {
      "email": {
        "invalid": "Signin.Fields.Email.Invalid",
        "label": "Signin.Fields.Email.Label",
        "required": "Signin.Fields.Email.Required"
      },
      "password": {
        "label": "Signin.Fields.Password.Label",
        "required": "Signin.Fields.Password.Required"
      }
    }
  },
  "startcampaignmodal": {
    "button": "StartCampaignModal.Button",
    "description": "StartCampaignModal.Description",
    "form": {
      "title": "StartCampaignModal.Form.Title"
    },
    "title": "StartCampaignModal.Title"
  },
  "staticsview": {
    "amendments": {
      "title": "StaticsView.Amendments.Title"
    }
  },
  "statistics": {
    "view": {
      "cgv": {
        "received": "Statistics.View.Cgv.Received"
      },
      "contracts": {
        "in": {
          "completion": "Statistics.View.Contracts.In.Completion",
          "negotiation": "Statistics.View.Contracts.In.Negotiation",
          "signature": "Statistics.View.Contracts.In.Signature"
        },
        "signed": "Statistics.View.Contracts.Signed"
      },
      "exclusive": {
        "acceptance": {
          "contracts": {
            "title": "Statistics.View.Exclusive.Acceptance.Contracts.Title"
          }
        },
        "contracts": "Statistics.View.Exclusive.Contracts"
      },
      "filter": {
        "button": {
          "apply": "Statistics.View.Filter.Button.Apply",
          "export": {
            "statistics": "Statistics.View.Filter.Button.Export.Statistics"
          },
          "generate": "Statistics.View.Filter.Button.Generate",
          "reset": "Statistics.View.Filter.Button.Reset"
        },
        "default": {
          "title": "Statistics.View.Filter.Default.Title"
        }
      },
      "filters": {
        "assistants": "Statistics.View.Filters.Assistants",
        "buyers": "Statistics.View.Filters.Buyers",
        "campaignsyear": "Statistics.View.Filters.CampaignsYear",
        "cdps": "Statistics.View.Filters.Cdps",
        "resps": "Statistics.View.Filters.Resps",
        "type": "Statistics.View.Filters.Type"
      },
      "in": {
        "completion": {
          "contracts": {
            "title": "Statistics.View.In.Completion.Contracts.Title"
          }
        },
        "negotiation": {
          "contracts": {
            "title": "Statistics.View.In.Negotiation.Contracts.Title"
          }
        },
        "signature": {
          "contracts": {
            "title": "Statistics.View.In.Signature.Contracts.Title"
          }
        }
      },
      "non": {
        "exclusive": {
          "acceptance": {
            "contracts": {
              "title": "Statistics.View.Non.Exclusive.Acceptance.Contracts.Title"
            }
          },
          "contracts": "Statistics.View.Non.Exclusive.Contracts"
        }
      },
      "number": {
        "of": {
          "manged": {
            "contracts": {
              "title": "Statistics.View.Number.Of.Manged.Contracts.Title"
            }
          },
          "suppliers": {
            "title": "Statistics.View.Number.Of.Suppliers.Title"
          }
        }
      },
      "rates": {
        "received": "Statistics.View.Rates.Received"
      },
      "signed": {
        "contracts": {
          "title": "Statistics.View.Signed.Contracts.Title"
        }
      },
      "title": "Statistics.View.Title"
    }
  },
  "suppliers": {
    "noresult": "Suppliers.NoResult",
    "search": {
      "label": "Suppliers.Search.Label"
    }
  },
  "tables": {
    "admintable": {
      "headers": {
        "assistant": "Tables.AdminTable.Headers.Assistant",
        "buyer2": "Tables.AdminTable.Headers.Buyer2",
        "contracttype": "Tables.AdminTable.Headers.ContractType",
        "locked": "Tables.AdminTable.Headers.Locked",
        "purchasemanager": "Tables.AdminTable.Headers.PurchaseManager",
        "suppliers": "Tables.AdminTable.Headers.Suppliers"
      }
    }
  },
  "teams": {
    "leaderuser": {
      "label": "Teams.LeaderUser.Label",
      "required": "Teams.LeaderUser.Required"
    },
    "noresult": "Teams.NoResult",
    "projectmanager": {
      "required": "Teams.ProjectManager.Required"
    },
    "teamtitle": "Teams.TeamTitle",
    "tenantadministrator": {
      "required": "Teams.TenantAdministrator.Required"
    },
    "type": {
      "label": "Teams.Type.Label",
      "marketmanager": "Teams.Type.MarketManager",
      "offerleader": "Teams.Type.OfferLeader",
      "other": "Teams.Type.Other",
      "required": "Teams.Type.Required"
    }
  },
  "template": {
    "content": {
      "save": {
        "error": "Template.Content.Save.Error",
        "success": "Template.Content.Save.Success"
      }
    },
    "folder": {
      "fields": {
        "name": {
          "label": "Template.Folder.Fields.Name.Label"
        }
      }
    }
  },
  "templatecomponents": {
    "templateoptions": {
      "buttons": {
        "edit": {
          "label": "TemplateComponents.TemplateOptions.Buttons.Edit.Label"
        }
      }
    }
  },
  "templatesview": {
    "buttons": {
      "addfolder": {
        "label": "TemplatesView.Buttons.AddFolder.Label"
      },
      "addtemplate": {
        "label": "TemplatesView.Buttons.AddTemplate.Label"
      },
      "new": {
        "label": "TemplatesView.Buttons.New.Label"
      }
    },
    "templatelist": {
      "delete": {
        "modal": {
          "description": "TemplatesView.TemplateList.Delete.Modal.Description",
          "title": "TemplatesView.TemplateList.Delete.Modal.Title"
        }
      }
    },
    "templatesheader": {
      "last": {
        "modified": "TemplatesView.TemplatesHeader.Last.Modified"
      },
      "name": "TemplatesView.TemplatesHeader.Name",
      "status": "TemplatesView.TemplatesHeader.Status"
    }
  },
  "tenants": {
    "name": {
      "label": "Tenants.Name.Label",
      "required": "Tenants.Name.Required"
    },
    "negotiationtype": {
      "annual": "Tenants.NegotiationType.Annual",
      "label": "Tenants.NegotiationType.Label",
      "overtime": "Tenants.NegotiationType.Overtime",
      "required": "Tenants.NegotiationType.Required"
    },
    "noresult": "Tenants.NoResult"
  },
  "unauthorized": {
    "message": "Unauthorized.Message",
    "title": "Unauthorized.Title"
  },
  "updateassignmentsmodal": {
    "description": "UpdateAssignmentsModal.Description",
    "title": "UpdateAssignmentsModal.Title"
  },
  "updatecampaignmodal": {
    "button": "UpdateCampaignModal.Button",
    "description": "UpdateCampaignModal.Description",
    "title": "UpdateCampaignModal.Title"
  },
  "updatecontractmodal": {
    "button": "UpdateContractModal.Button",
    "description": "UpdateContractModal.Description",
    "title": "UpdateContractModal.Title"
  },
  "validatedialog": {
    "buttons": {
      "save": {
        "label": "ValidateDialog.Buttons.Save.Label"
      }
    },
    "content": {
      "checkbox": {
        "label": "ValidateDialog.Content.Checkbox.Label"
      },
      "description": "ValidateDialog.Content.Description",
      "label": "ValidateDialog.Content.Label"
    },
    "title": "ValidateDialog.Title"
  },
  "vendorview": {
    "stepper": {
      "checkandcompletedetails": "VendorView.Stepper.CheckAndCompleteDetails",
      "checkthecontract": "VendorView.Stepper.CheckTheContract",
      "importrequestdocuments": "VendorView.Stepper.ImportRequestDocuments",
      "itsallover": "VendorView.Stepper.ItsAllOver",
      "signthecontract": "VendorView.Stepper.SignTheContract"
    }
  },
  "verbs": {
    "accept": "Verbs.Accept",
    "add": "Verbs.Add",
    "apply": "Verbs.Apply",
    "autocomplete": {
      "modify": "Verbs.Autocomplete.Modify"
    },
    "browse": "Verbs.Browse",
    "cancel": "Verbs.Cancel",
    "choose": "Verbs.Choose",
    "close": "Verbs.Close",
    "complete": "Verbs.Complete",
    "consult": "Verbs.Consult",
    "create": "Verbs.Create",
    "delete": "Verbs.Delete",
    "done": "Verbs.Done",
    "download": "Verbs.Download",
    "duplicate": "Verbs.Duplicate",
    "invalidate": "Verbs.Invalidate",
    "link": "Verbs.Link",
    "move": "Verbs.Move",
    "next": "Verbs.Next",
    "previous": "Verbs.Previous",
    "publish": "Verbs.Publish",
    "rectify": "Verbs.Rectify",
    "reject": "Verbs.Reject",
    "rename": "Verbs.Rename",
    "renegociate": "Verbs.Renegociate",
    "restore": "Verbs.Restore",
    "save": "Verbs.Save",
    "search": "Verbs.Search",
    "sign": "Verbs.Sign",
    "template": {
      "modify": "Verbs.Template.Modify"
    },
    "test": "Verbs.Test",
    "unpublish": "Verbs.Unpublish",
    "update": "Verbs.Update",
    "validate": "Verbs.Validate",
    "workflow": {
      "modify": "Verbs.Workflow.Modify"
    }
  },
  "workflow": {
    "step": {
      "edit": {
        "title": "WorkFlow.Step.Edit.Title"
      },
      "author": {
        "label": "Workflow.Step.Author.Label"
      },
      "buttons": {
        "add": "Workflow.Step.Buttons.Add",
        "back": {
          "label": "Workflow.Step.Buttons.Back.Label"
        },
        "mandatory": {
          "add": "Workflow.Step.Buttons.Mandatory.Add"
        },
        "notifycontributors": {
          "add": "Workflow.Step.Buttons.NotifyContributors.Add"
        },
        "others": {
          "add": "Workflow.Step.Buttons.Others.Add"
        }
      },
      "completed": {
        "label": "Workflow.Step.Completed.Label"
      },
      "contributor": {
        "by": "Workflow.Step.Contributor.By"
      },
      "dialogs": {
        "delete": {
          "message": "Workflow.Step.Dialogs.Delete.Message",
          "title": "Workflow.Step.Dialogs.Delete.Title"
        }
      },
      "duration": {
        "message": "Workflow.Step.Duration.Message"
      },
      "fields": {
        "completionmessage": {
          "label": "Workflow.Step.Fields.CompletionMessage.Label",
          "placeholder": "Workflow.Step.Fields.CompletionMessage.Placeholder"
        },
        "contributors": {
          "required": "Workflow.Step.Fields.Contributors.Required"
        },
        "identifier": {
          "required": "Workflow.Step.Fields.Identifier.Required"
        },
        "mandatory": {
          "label": "Workflow.Step.Fields.Mandatory.Label",
          "required": "Workflow.Step.Fields.Mandatory.Required"
        },
        "name": {
          "default": "Workflow.Step.Fields.Name.Default",
          "label": "Workflow.Step.Fields.Name.Label",
          "required": "Workflow.Step.Fields.Name.Required"
        },
        "notifycontributors": {
          "label": "Workflow.Step.Fields.NotifyContributors.Label"
        },
        "others": {
          "label": "Workflow.Step.Fields.Others.Label"
        }
      }
    },
    "contributor": {
      "buttons": {
        "condition": {
          "add": "Workflow.Contributor.Buttons.Condition.Add",
          "edit": "Workflow.Contributor.Buttons.Condition.Edit"
        },
        "remove": {
          "nextstepcontributorwarningtext": "Workflow.Contributor.Buttons.Remove.NextStepContributorWarningText"
        }
      },
      "dialogs": {
        "delete": {
          "message": "Workflow.Contributor.Dialogs.Delete.Message",
          "title": "Workflow.Contributor.Dialogs.Delete.Title"
        }
      },
      "fields": {
        "access": {
          "0": {
            "label": "Workflow.Contributor.Fields.Access.0.Label"
          },
          "1": {
            "label": "Workflow.Contributor.Fields.Access.1.Label"
          },
          "2": {
            "label": "Workflow.Contributor.Fields.Access.2.Label"
          },
          "3": {
            "label": "Workflow.Contributor.Fields.Access.3.Label"
          },
          "4": {
            "label": "Workflow.Contributor.Fields.Access.4.Label"
          },
          "5": {
            "label": "Workflow.Contributor.Fields.Access.5.Label"
          },
          "label": "Workflow.Contributor.Fields.Access.Label",
          "required": "Workflow.Contributor.Fields.Access.Required"
        },
        "entity": {
          "required": "Workflow.Contributor.Fields.Entity.Required"
        }
      }
    },
    "contributorpicker": {
      "label": "Workflow.ContributorPicker.Label"
    },
    "edit": {
      "button": "Workflow.Edit.Button"
    },
    "settings": {
      "save": {
        "success": "Workflow.Settings.Save.Success"
      },
      "title": "Workflow.Settings.Title"
    }
  }
};