import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

import { toHslColor } from '@utils/hue-from-string';
import { compute } from '@utils/username-helpers';

function UserChip({
  email,
  pictureUrl = '/broken-image.jpg',
  firstName = '',
  lastName = '',
  externalId = '',
  ...rest
}) {
  const username = compute(email, firstName, lastName, externalId);
  return (
    <Chip
      avatar={
        <Avatar sx={{ bgcolor: toHslColor(email ?? '') }} src={pictureUrl}>
          {username.first}
        </Avatar>
      }
      label={username.value}
      {...rest}
    />
  );
}

UserChip.propTypes = {
  email: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  externalId: PropTypes.string,
};

UserChip.defaultProps = {
  pictureUrl: '/broken-image.jpg',
  firstName: '',
  lastName: '',
  externalId: '',
};

export default UserChip;
