/* eslint-disable no-bitwise */
const hueFromString = stringValue =>
  stringValue
    .split('')
    .map(s => s.charCodeAt(0))
    .reduce((acc, cur) => cur + ((acc << 5) - acc), 0) % 360;

const toHslColor = stringValue =>
  `hsl(${hueFromString(stringValue)}, 30%, 80%)`;

export { toHslColor };
export default hueFromString;
