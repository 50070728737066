import React from 'react';
import { useSetRecoilState } from 'recoil';

import Fetching from '@components/Fetching/Fetching';

import { currentCampaignSelector } from '@recoil/campaign';

import useFetch from '@hooks/useFetch';

function CurrentCampaignFetcher({ children }) {
  const { data, fetching } = useFetch({
    url: '/lavieclaire/campaign/current',
    method: 'GET',
  });
  const setCurrentCampaign = useSetRecoilState(currentCampaignSelector);
  React.useEffect(() => {
    if (data !== undefined && data !== null) {
      setCurrentCampaign(data);
    }
  }, [data, setCurrentCampaign]);
  return <Fetching fetching={fetching}>{children}</Fetching>;
}

export default CurrentCampaignFetcher;
