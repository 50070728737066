import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import Fetching from '@components/Fetching/Fetching';

import useFetch from '@hooks/useFetch';
import useWindowTitle from '@hooks/useWindowTitle';
import ContractWorkflowHeader from './ContractWorkflowHeader';
import WorkflowContextProvider from './WorkflowContextProvider';
import WorkflowFormikProvider from './WorkflowFormikProvider';
import WorkflowEditor from './WorkflowEditor';

function ContractWorkflowView({ backUrl }) {
  const { formatMessage } = useIntl();
  const { contractId } = useParams();

  const { data, fetching } = useFetch({
    url: `/contracts/${contractId}?includeContent=true&includeWorkflow=true&includeStatus=true`,
    cachePolicy: 'network-only',
  });

  const { data: varData, fetching: varFetching } = useFetch({
    url: `/contracts/${contractId}/variables`,
    cachePolicy: 'network-only',
  });

  useWindowTitle(data?.document?.name);

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Fetching
        fetching={fetching || varFetching}
        sx={{ flexGrow: 1, mr: 2, mb: 2 }}
      >
        <WorkflowContextProvider
          workflow={data?.workflow}
          defaultStep={{
            name: formatMessage({ id: 'Workflow.Step.Fields.Name.Default' }),
            mandatoryContributors: [],
            otherContributors: [],
          }}
        >
          <WorkflowFormikProvider
            FormProps={{ style: { width: '100%', padding: '0 16px' } }}
          >
            <ContractWorkflowHeader
              id={data?.id}
              name={data?.name}
              backUrl={backUrl}
            />
            <WorkflowEditor variablesData={varData} sx={{ pr: 2, pb: 2 }} />
          </WorkflowFormikProvider>
        </WorkflowContextProvider>
      </Fetching>
    </Box>
  );
}

export default ContractWorkflowView;
