import { atom, selector } from 'recoil';

export const currentCampaign = atom({
  key: 'currentCampaign',
  default: null,
});

export const currentCampaignSelector = selector({
  key: 'currentCampaignSelector',
  get: ({ get }) => {
    const state = get(currentCampaign);
    return state?.year ?? 0;
  },
  set: ({ set }, newValue) => {
    set(currentCampaign, newValue);
  },
});
