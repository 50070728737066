import React from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import isEqual from 'react-fast-compare';

function FormikSubmitOnChange({ noValidate = false }) {
  const formik = useFormikContext();
  const [valuesState, setValuesState] = React.useState(null);
  const submitForm = React.useMemo(
    () =>
      debounce(
        () => {
          formik.submitForm();
        },
        500,
        { maxWait: 1500 }
      ),
    []
  );
  React.useEffect(
    () => () => {
      submitForm.cancel();
    },
    []
  );
  React.useEffect(() => {
    if (!isEqual({ ...valuesState }, { ...formik.values })) {
      setValuesState(formik.values);
    }
  }, [valuesState, formik.values, setValuesState]);
  React.useEffect(() => {
    if (valuesState && !noValidate && formik.isValid) {
      submitForm();
    }
  }, [valuesState, formik.isValid, submitForm, noValidate]);
  React.useEffect(() => {
    if (valuesState && noValidate) {
      submitForm();
    }
  }, [valuesState, submitForm, noValidate]);
  return null;
}

export default FormikSubmitOnChange;
