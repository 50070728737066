/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import ContributorChip from '@components/Chips/ContributorChip';
import Box from '@mui/material/Box';
import StatusChip from '@components/Chips/StatusChip';

function WorkflowStepCard({
  selected,
  name,
  isFirst,
  mandatoryContributors = [],
  otherContributors = [],
  endTime,
}) {
  const isCompleted = Boolean(endTime);

  return (
    <Card
      variant="elevation"
      sx={{
        borderRadius: 2,
        border: selected ? '1px solid' : '0px solid',
        borderColor: selected ? 'primary.main' : 'transparent',
        color: selected ? 'text.primary' : 'text.primary',
      }}
    >
      <CardContent sx={{ py: 1, px: 2, '&:last-child': { pb: 1 } }}>
        {!isFirst &&
          !otherContributors.length &&
          !mandatoryContributors.length && (
            <WarningAmberIcon color="warning" sx={{ float: 'right' }} />
          )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">{name}</Typography>
          <Box
            sx={{
              width: 15,
              height: 15,
              p: 0.2,
              bgcolor: selected ? 'primary.main' : 'transparent',
              borderRadius: '50%',
              border: '1px solid',
            }}
          />
        </Stack>
        {(!!mandatoryContributors.length || !!otherContributors.length) && (
          <Stack
            display="inline-flex"
            direction="row"
            flexWrap="wrap"
            gap={1}
            mt={1}
          >
            {mandatoryContributors.map(
              ({ assignments, contributor: { type, entity } }, index) =>
                type === 2 && assignments?.length ? (
                  assignments.map(assignment => (
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      justifyContent="space-between"
                      gap={5}
                      sx={{ position: 'relative', width: '100%' }}
                    >
                      <ContributorChip
                        sx={{ bgcolor: 'grey.200' }}
                        type={1}
                        entityId={assignment?.entity?.Id}
                        entity={assignment?.entity}
                        authorLabel={
                          <FormattedMessage id="Workflow.Step.Author.Label" />
                        }
                      />
                    </Stack>
                  ))
                ) : (
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    key={index}
                    gap={5}
                    sx={{ position: 'relative', width: '100%' }}
                  >
                    <ContributorChip
                      sx={{ bgcolor: 'grey.200' }}
                      type={type}
                      entityId={entity?.id}
                      entity={entity}
                      authorLabel={
                        <FormattedMessage id="Workflow.Step.Author.Label" />
                      }
                    />
                  </Stack>
                )
            )}
            {otherContributors.map(
              ({ assignments, contributor: { type, entity } }, index) =>
                type === 2 && assignments?.length ? (
                  assignments.map(assignment => (
                    <ContributorChip
                      sx={{ bgcolor: 'grey.200' }}
                      type={1}
                      entityId={assignment?.entity?.Id}
                      entity={assignment?.entity}
                      authorLabel={
                        <FormattedMessage id="Workflow.Step.Author.Label" />
                      }
                    />
                  ))
                ) : (
                  <ContributorChip
                    key={index}
                    sx={{ bgcolor: 'grey.200' }}
                    type={type}
                    entityId={entity?.id}
                    entity={entity}
                    authorLabel={
                      <FormattedMessage id="Workflow.Step.Author.Label" />
                    }
                  />
                )
            )}
          </Stack>
        )}
        <Stack alignItems="flex-end" justifyContent="flex-end" sx={{ my: 2 }}>
          {isCompleted && <StatusChip status={10} />}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default WorkflowStepCard;
