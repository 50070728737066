import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FourKIcon from '@mui/icons-material/FourK';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const StyledChip = styled(Chip)(({ theme, color }) => ({
  color: theme.palette[color].contrastText,
  borderColor: theme.palette[color].main,
  padding: '3px',
  height: 'auto',
  borderRadius: '13px',
  fontSize: '9px',
  textTransform: 'uppercase',
  fontWeight: 700,
  marginTop: 0,
}));

export default function StatusChip({ status, ...rest }) {
  const STATUSES = [
    {
      color: 'chipSuccess',
      text: 'Chip.Received',
      icon: CheckCircleIcon,
    },
    {
      color: 'chipWarning',
      text: 'Chip.Waiting',
      icon: AccessTimeFilledIcon,
    },
    {
      color: 'chipError',
      text: 'Chip.Completion',
      icon: AccessTimeFilledIcon,
    },
    {
      color: 'chipWarning',
      text: 'Chip.Negotiation',
      icon: CheckCircleIcon,
    },
    {
      color: 'chipError',
      text: 'Chip.Missing',
      icon: AccessTimeFilledIcon,
    },
    {
      color: 'chipSuccess',
      text: 'Chip.Published',
      icon: CheckCircleIcon,
    },
    {
      color: 'chipDefault',
      text: 'Chip.Draft',
      icon: FourKIcon,
    },
    {
      color: 'chipPending',
      text: 'Chip.Pending',
      icon: CheckCircleIcon,
    },
    {
      color: 'chipSuccess',
      text: 'Chip.Signed',
      icon: CheckCircleIcon,
    },
    {
      color: 'chipSuccess',
      text: 'Chip.Validated',
      icon: CheckCircleIcon,
    },
  ];

  const chipStatus = STATUSES[status - 1];
  if (!chipStatus) return null;
  return (
    <StyledChip
      color={chipStatus.color}
      icon={<chipStatus.icon sx={{ fontSize: '13px' }} />}
      label={<FormattedMessage id={chipStatus.text} />}
      {...rest}
    />
  );
}

StatusChip.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).isRequired,
};
