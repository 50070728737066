import { useCallback, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { refresh } from '@utils/authentication';
import {
  tenantTokenSelector,
  tenantAuthenticationState,
  tenantIsAuthenticatedSelector,
} from '@recoil/tenant-token';
import { useFetch as useLibFetch } from './useLibFetch';

const urlPrefix = process.env.REACT_APP_API_URI;

const processUrl = urlProp => {
  if (urlProp === undefined) return undefined;
  if (urlProp === null) return null;
  const slash = urlProp.startsWith('/') ? '' : '/';
  return `${urlPrefix}${slash}${urlProp}`;
};

export default function useFetch(props) {
  const ref = useRef({});
  const optionsRef = useRef({});
  const doFetchRef = useRef(null);
  const navigate = useNavigate();
  const token = useRecoilValue(tenantTokenSelector);
  const localState = useRecoilValue(tenantAuthenticationState);
  const setLocalState = useSetRecoilState(tenantIsAuthenticatedSelector);

  const url = processUrl(props?.url);
  const cachePolicy = props?.cachePolicy || 'network-only';

  ref.current.onSuccess = props?.onSuccess;
  const onSuccess = useCallback((...args) => {
    optionsRef.current = {};
    ref.current.onSuccess?.(...args);
  }, []);

  const onErrorProp = props?.onError;
  // TODO: check if onError can call stale methods
  const onError = useCallback(
    async responseOrError => {
      const isError = responseOrError instanceof Error;
      if (isError && responseOrError.name === 'AbortError') {
        return;
      }

      if (responseOrError.status === 401) {
        const newToken = await refresh(
          localState.token.value,
          localState.refreshToken.value
        );
        if (newToken.succeeded) {
          setLocalState(newToken);
          let headers = {
            Authorization: `Bearer ${newToken.token.value}`,
          };
          if (!(props?.noContentType ?? false)) {
            headers['Content-Type'] = 'application/json';
          }
          headers = { ...headers, ...props?.init?.headers };
          doFetchRef.current({
            ...optionsRef.current,
            headers,
          });
        } else {
          optionsRef.current = {};
          setLocalState(null);
          navigate('/signin');
        }
      }
      onErrorProp?.(responseOrError);
    },
    [
      onErrorProp,
      localState?.refreshToken.value,
      localState?.token.value,
      navigate,
      setLocalState,
      props?.noContentType,
      props?.init,
    ]
  );

  let headers = {
    Authorization: `Bearer ${token}`,
  };
  if (!(props?.noContentType ?? false)) {
    headers['Content-Type'] = 'application/json';
  }
  headers = { ...headers, ...props?.init?.headers };

  const result = useLibFetch({
    ...props,
    url,
    cachePolicy,
    init: {
      ...props?.init,
      headers,
    },
    onSuccess,
    onError,
  });

  const innerDoFetch = result.doFetch;
  const doFetch = useCallback(
    options => {
      optionsRef.current = options;
      const processedUrl = processUrl(options?.url);
      return innerDoFetch({
        ...options,
        url: processedUrl,
      });
    },
    [innerDoFetch]
  );

  useEffect(() => {
    doFetchRef.current = doFetch;
  }, [doFetch]);

  return useMemo(() => ({ ...result, doFetch }), [result, doFetch]);
}
