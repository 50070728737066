import React from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { tenantIsAuthenticatedSelector } from '@recoil/tenant-token';

function TenantAuthenticatedRoute() {
  const location = useLocation();
  const isAuthenticated = useRecoilValue(tenantIsAuthenticatedSelector);
  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default TenantAuthenticatedRoute;
