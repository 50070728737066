import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Viewer as CorePdfViewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Box from '@mui/material/Box';

import Fetching from '@components/Fetching/Fetching';

import useFetch from '@hooks/useFetch';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export function PdfViewer({ fileUrl }) {
  const layoutPlugin = defaultLayoutPlugin();
  return (
    <Box sx={{ height: '80vh' }}>
      <CorePdfViewer fileUrl={fileUrl} plugins={[layoutPlugin]} />
    </Box>
  );
}

function Viewer({ fetchUrl }) {
  const { data, fetching, error } = useFetch({
    method: 'GET',
    url: fetchUrl,
  });

  const fileUrl = data?.uri;

  return (
    <Fetching fetching={fetching}>
      {!fetching &&
        (error ? (
          <FormattedMessage id="Components.Viewer.Error.DefaultMessage" />
        ) : (
          Boolean(fileUrl) && <PdfViewer fileUrl={fileUrl} />
        ))}
    </Fetching>
  );
}

export default Viewer;
