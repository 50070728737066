import { atom, selector } from 'recoil';
import { resolve } from '@utils/authentication';
import decode from 'jwt-decode';

const defaultState = resolve('tenant-token');

export const tenantAuthenticationState = atom({
  key: 'tenantAuthenticationState',
  default: defaultState,
});

export const tenantIsAuthenticatedSelector = selector({
  key: 'tenantIsAuthenticatedSelector',
  get: ({ get }) => {
    const state = get(tenantAuthenticationState);
    return state !== null;
  },
  set: ({ set }, newValue) => {
    if (newValue === null) {
      localStorage.removeItem('tenant-token');
    } else {
      localStorage.setItem('tenant-token', JSON.stringify(newValue));
    }
    set(tenantAuthenticationState, newValue);
  },
});

export const tenantTokenSelector = selector({
  key: 'tenantTokenSelector',
  get: ({ get }) => {
    const state = get(tenantAuthenticationState);
    return state?.token?.value ?? '';
  },
});

export const tenantDecodedTokenSelector = selector({
  key: 'tenantDecodedTokenSelector',
  get: ({ get }) => {
    const value = get(tenantTokenSelector);
    return value ? decode(value) : '';
  },
});

export const tenantUserIdentifierSelector = selector({
  key: 'tenantUserIdentifierSelector',
  get: ({ get }) => {
    const decoded = get(tenantDecodedTokenSelector);
    return decoded ? decoded.nameid : null;
  },
});

export const tenantUserAvatarSelector = selector({
  key: 'tenantUserAvatarSelector',
  get: ({ get }) => {
    const decoded = get(tenantDecodedTokenSelector);
    return decoded === ''
      ? {}
      : {
          email: decoded.email,
          firstName: decoded.given_name ?? '',
          lastName: decoded.family_name ?? '',
        };
  },
});

export const tenantIdentifierSelector = selector({
  key: 'tenantIdentifierSelector',
  get: ({ get }) => {
    const value = get(tenantDecodedTokenSelector);
    const key =
      'https://schemas.seraphin.legal/2022/01/identity/claims/tenantidentifier';
    return value[key] ?? '';
  },
});

export const tenantPermissionsSelector = selector({
  key: 'tenantPermissionsSelector',
  get: ({ get }) => {
    const state = get(tenantAuthenticationState);
    if ((state?.token?.value ?? '') === '') {
      return '';
    }
    const decoded = decode(state?.token?.value);
    return (
      decoded[
        'https://schemas.seraphin.legal/2022/01/identity/claims/permissions'
      ] ?? ''
    )
      .split('')
      .map(c => c.charCodeAt(0));
  },
});

export const tenantRolesSelector = selector({
  key: 'tenantRolesSelector',
  get: ({ get }) => {
    const state = get(tenantAuthenticationState);
    if ((state?.token?.value ?? '') === '') {
      return '';
    }
    const decoded = decode(state?.token?.value);
    return Array.isArray(decoded.role) ? decoded.role : [decoded.role];
  },
});

export const tenantTokenNavigated = atom({
  key: 'tenantTokenNavigated',
  default: true,
});
