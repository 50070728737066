import { useLayoutEffect, useRef, useState } from 'react';

export default function useDebounced(current, ms) {
  const ref = useRef(null);

  const [debounced, setDebounced] = useState(current);

  useLayoutEffect(() => {
    clearTimeout(ref.current);

    if (ms) {
      ref.current = setTimeout(() => {
        setDebounced(current);
      }, ms);
    } else {
      setDebounced(current);
    }

    return () => {
      clearTimeout(ref.current);
    };
  }, [current, ms]);

  return ms ? debounced : current;
}
